import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { tailspin } from 'ldrs'
import ReferenceFieldPreview from './ReferenceFieldPreview'
import ReferenceFieldEdit from './ReferenceFieldEdit'

export default function ReferenceField({
  fieldName,
  label,
  value,
  relatedRecords,
  showRelatedRecords,
  setShowRelatedRecords,
  isEditing = false,
  onFieldChange = () => { },
  isChanged = false,
  fieldDefinition,
  recordId,
  messageId
}) {
  const [localText, setLocalText] = useState(value)
  const textAreaRef = useRef(null) // Kept, in case you need a textarea in the future
  const isUpdateable = fieldDefinition?.updateable ?? false

  const compositeKey = `${messageId}_${recordId}`;
  const recordType = useSelector((state) => state.isEditing.editingRecords[compositeKey]?.recordType) || 'Unknown';

  tailspin.register()

  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }, [isEditing, localText])

  useEffect(() => {
    if (!isEditing) {
      setLocalText(value)
    }
  }, [value, isEditing])

  const relatedMatch = (relatedRecords || []).find((r) => r.Id === value);

  const getDisplayName = (record) => {
    if (!record) return ''
    if (record.Name) return record.Name
    if (record.FirstName && record.LastName) {
      return `${record.FirstName} ${record.LastName}`
    }
    return record.Id
  }

  const displayText = relatedMatch ? getDisplayName(relatedMatch) : value

  // Decide which child to render
  if (!isEditing || !isUpdateable) {
    return (
      <ReferenceFieldPreview
        fieldName={fieldName}
        label={label}
        value={displayText}
        isChanged={isChanged}
        relatedMatch={relatedMatch}
        showRelatedRecords={showRelatedRecords}
        setShowRelatedRecords={setShowRelatedRecords}
        recordId={recordId}
        messageId={messageId}
      />
    )
  }


  return (
    <ReferenceFieldEdit
      fieldName={fieldName}
      label={label}
      value={value}                 
      displayText={displayText}     
      isChanged={isChanged}
      getDisplayName={getDisplayName}
      onFieldChange={onFieldChange}
      recordType={recordType}
    />
  )
}