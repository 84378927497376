import React from 'react';

const ChatHeroStyles = () => (
  <style>{`
    .shiny-button {
      position: relative;
      overflow: hidden;
    }
    .shiny-button::after {
      content: "";
      position: absolute;
      top: 0;
      left: -75%;
      width: 50%;
      height: 100%;
      background: linear-gradient(120deg, transparent, rgba(255,255,255,0.7), transparent);
      transform: skewX(-25deg);
      animation: shine 3s infinite;
    }
    @keyframes shine {
      0% {
        left: -75%;
      }
      20% {
        left: -75%;
      }
      30% {
        left: 125%;
      }
      100% {
        left: 125%;
      }
    }
  `}</style>
);

export default ChatHeroStyles;