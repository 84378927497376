import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organization: null, // Stores organization information from the server
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action) => {
      state.organization = action.payload;
    },
    clearOrganization: (state) => {
      state.organization = null;
    },
  },
});

export const { setOrganization, clearOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;