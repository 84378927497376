import React, { useState, useRef, useEffect } from 'react';
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion'; // <-- added import

export default function FieldValue({
  fieldName,
  label,
  value,
  relatedRecords,
  showRelatedRecords,
  setShowRelatedRecords,
  isEditing = false,
  onFieldChange = () => {},
  isChanged = false,
  fieldDefinition,
  recordId,
  messageId
}) {
  const [copied, setCopied] = useState(false);
  const [localText, setLocalText] = useState(value);
  const textAreaRef = useRef(null);
  const isUpdateable = fieldDefinition?.updateable ?? false;

  useEffect(() => {
    setLocalText(value);
  }, [value]);

  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [isEditing, localText]);

  useEffect(() => {
    if (!isEditing) {
      setLocalText(value);
    }
  }, [isEditing, value]);

  const relatedMatch = (relatedRecords || []).find((r) => r.Id === value);
  let displayText = value;
  if (relatedMatch) {
    displayText =
      relatedMatch.Name ||
      (relatedMatch.FirstName && relatedMatch.LastName
        ? `${relatedMatch.FirstName} ${relatedMatch.LastName}`
        : relatedMatch.Id);
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(String(value));
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleClickRelated = () => {
    if (relatedMatch) {
      setShowRelatedRecords(!showRelatedRecords);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalText(newValue);
    onFieldChange(fieldName, newValue);
  };

  const handleInput = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  const isFlashing = useSelector((state) => !!state.flashFields[`${messageId}_${recordId}`]?.[fieldName]);


  const innerContent = (
    <>
      <strong className={`mr-1 ${isChanged ? 'text-indigo-600' : ''}`}>{label}:</strong>

      {isEditing && isUpdateable ? (
        <textarea
          ref={textAreaRef}
          className={`border ${isChanged ? 'border-indigo-600' : 'border-gray-300'} rounded px-1 py-1 flex-1 resize-none focus:border-indigo-600 focus:outline-none`}
          rows={1}
          value={localText || ""}
          onChange={handleChange}
          onInput={handleInput}
          style={{ overflow: 'hidden' }}
        />
      ) : relatedMatch ? (
        <button
          type="button"
          onClick={handleClickRelated}
          className="text-indigo-600 hover:text-indigo-800 truncate overflow-hidden whitespace-nowrap text-ellipsis flex-1 text-left"
        >
          {displayText}
        </button>
      ) : (
        <span className="truncate overflow-hidden whitespace-nowrap text-ellipsis flex-1">
          {displayText}
        </span>
      )}

      {!isEditing && (
        <button
          onClick={handleCopy}
          aria-label="Copy field value"
          className="inline-flex items-center ml-1 opacity-0 group-hover:opacity-100 transition-opacity flex-none"
        >
          {copied ? (
            <CheckIcon className="h-4 w-4 text-indigo-600" />
          ) : (
            <DocumentDuplicateIcon className="h-3 w-3 text-gray-300 hover:text-indigo-600" />
          )}
        </button>
      )}
    </>
  );

  // Conditionally wrap the content in a motion.div when not editing and flashing.
  if (!isEditing && isFlashing) {
    return (
      <motion.div
        className="relative group text-xs sm:text-xsm md:text-xsm lg:text-xsm flex items-center max-w-full text-indigo-600"
        initial={{ opacity: 0.7 }}
        animate={{ opacity: [0.7, 1, 0.7] }}
        transition={{ duration: 2.0, repeat: Infinity, ease: 'easeInOut' }}
      >
        {innerContent}
      </motion.div>
    );
  }

  // Otherwise, render the regular container.
  return (
    <div className="relative group text-xs sm:text-xsm md:text-xsm lg:text-xsm flex items-center max-w-full text-gray-600">
      {innerContent}
    </div>
  );
}