// src/redux/slices/generatingSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isGenerating: false,
  generatingText: '', // Default text
};

const generatingSlice = createSlice({
  name: 'generating',
  initialState,
  reducers: {
    setGenerating: (state, action) => {
      state.isGenerating = action.payload;
      // If not generating, reset text to default
      if (!action.payload) {
        state.generatingText = '';
      }
    },
    setGeneratingText: (state, action) => {
      state.generatingText = action.payload;
    },
  },
});

export const { setGenerating, setGeneratingText } = generatingSlice.actions;

export const setGeneratingWithTimeout = (value) => (dispatch) => {
  dispatch(setGenerating(value));
  if (value === true) {
    setTimeout(() => {
      dispatch(setGenerating(false));
    }, 10000); 
  }
};

export default generatingSlice.reducer;