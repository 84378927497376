import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  draftsByCompositeKey: {}
};

const draftRecordSlice = createSlice({
  name: 'draftRecord',
  initialState,
  reducers: {
    initializeDraft: (state, action) => {
      const { recordId, messageId, initialValues } = action.payload;
      const key = `${messageId}_${recordId}`;
      state.draftsByCompositeKey[key] = { ...initialValues };
    },
    updateDraftField: (state, action) => {
      const { recordId, messageId, fieldName, newValue, displayName } = action.payload;
      const key = `${messageId}_${recordId}`;
      if (!state.draftsByCompositeKey[key]) {
        state.draftsByCompositeKey[key] = {};
      }
      state.draftsByCompositeKey[key][fieldName] = {
        value: newValue,
        displayName: displayName !== undefined ? displayName : newValue
      };
    },
    clearDraft: (state, action) => {
      const { recordId, messageId } = action.payload;
      const key = `${messageId}_${recordId}`;
      delete state.draftsByCompositeKey[key];
    },
    removeDraftField: (state, action) => {
      const { recordId, messageId, fieldName } = action.payload;
      const key = `${messageId}_${recordId}`;
      if (state.draftsByCompositeKey[key]) {
        delete state.draftsByCompositeKey[key][fieldName];
      }
    },
    clearAllDrafts: (state) => {
      state.draftsByCompositeKey = {};
    }
  }
});

export const {
  initializeDraft,
  updateDraftField,
  clearDraft,
  removeDraftField,
  clearAllDrafts
} = draftRecordSlice.actions;
export default draftRecordSlice.reducer;