import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const ReasoningCard = () => {
    const generatingText = useSelector((state) => state.generating.generatingText);
    const [dots, setDots] = useState('');

    // Animate ellipses every 500ms
    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prev) => (prev === '...' ? '' : prev + '.'));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="flex items-start justify-start w-full my-2">
            <img
                src="/assets/Icon_Black.png"
                alt="System Icon"
                className="h-6 w-6 mt-2 mr-2"
            />
            <div className="relative w-full md:w-auto">
                {/* System message bubble */}
                <motion.div
                    className="px-3 pt-3 pb-1 bg-gray-100 text-left rounded-lg"
                    initial={{ opacity: 0.7 }}
                    animate={{ opacity: [0.7, 1, 0.7] }}
                    transition={{ duration: 2.0, repeat: Infinity, ease: "easeInOut" }} // Continuous oscillation
                >
                    <div className="text-sm text-gray-800 whitespace-pre-wrap">
                        {generatingText}
                        <motion.span
                            animate={{ opacity: [0.3, 1, 0.3] }} // Pulse effect for ellipses
                            transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }}
                        >
                            {dots}
                        </motion.span>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default ReasoningCard;