// flashFieldsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // keyed by composite key: `${messageId}_${recordId}`
};

const flashFieldsSlice = createSlice({
  name: 'flashFields',
  initialState,
  reducers: {
    setFlashForFields: (state, action) => {
      const { recordId, fields, messageId } = action.payload;
      const key = `${messageId}_${recordId}`;
      if (!state[key]) {
        state[key] = {};
      }
      fields.forEach((field) => {
        state[key][field] = true;
      });
    },
    
    clearFlashForFields: (state, action) => {
      const { recordId, fields, messageId } = action.payload;
      const key = `${messageId}_${recordId}`;
      if (!state[key]) return;
      fields.forEach((field) => {
        state[key][field] = false;
      });
    },
  },
});

export const { setFlashForFields, clearFlashForFields } = flashFieldsSlice.actions;

// Thunk action to flash fields briefly
export const flashFields = ({ recordId, fields, messageId }) => (dispatch) => {
  dispatch(setFlashForFields({ recordId, fields, messageId }));
  // Clear flash after 5 seconds
  setTimeout(() => {
    dispatch(clearFlashForFields({ recordId, fields, messageId }));
  }, 5000);
};

export default flashFieldsSlice.reducer;