import React, { useState, useEffect } from 'react';
import MessageThread from './thread/MessageThread.jsx';
import ChatInput from './input/ChatInput.jsx';
import Generating from './input/Generating.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { setGenerating, setGeneratingText } from '../../redux/slices/generatingSlice.js';

const ChatContainer = ({ isEditing }) => {
  const isGenerating = useSelector((state) => state.generating.isGenerating);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const user = useSelector((state) => state.user.user);
  const onboarding = useSelector((state) => state.ui.onboarding);
  const startingUp = useSelector((state) => state.ui.startingUp);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!startingUp) {
      dispatch(setGenerating(false));
    }
  }, [startingUp, dispatch]);

  return (
    <div className="w-full h-[650px] flex flex-col bg-[#F3F3F3] rounded-b-xl">
      <div className="flex-1 h-full overflow-y-auto">
        <MessageThread onAtBottomChange={setIsAtBottom} />
      </div>

      <motion.div
        initial={{ opacity: 1, y: 0 }}
        animate={isAtBottom ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.3 }}
      >
        {!isEditing && isGenerating && <Generating />}
      </motion.div>

      <div>
        <ChatInput />
      </div>
    </div>
  );
};

export default ChatContainer;