import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DocumentDuplicateIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { clearValidationError, setValidationError } from '../../../../../../redux/slices/validationSlice.js';

function safeDateParse(dateString) {
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? null : date;
}

function formatDateAsYMD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export default function DateField({
  fieldName,
  label,
  value,
  rawValue,
  isEditing,
  onFieldChange,
  isChanged,
  fieldDefinition,
  recordId,
  messageId,
}) {
  const dispatch = useDispatch();
  const isUpdateable = fieldDefinition?.updateable ?? false;
  const [copied, setCopied] = useState(false);
  const initialDate = rawValue ? safeDateParse(rawValue) : (value ? safeDateParse(value) : null);
  const [selectedDate, setSelectedDate] = useState(initialDate);

  useEffect(() => {
    setSelectedDate(rawValue ? safeDateParse(rawValue) : (value ? safeDateParse(value) : null));
  }, [rawValue, value]);

  useEffect(() => {
    if (fieldDefinition?.required) {
      if (!selectedDate) {
        dispatch(
          setValidationError({
            recordId,
            messageId,
            fieldName,
            errorType: 'Required field',
          })
        );
      } else {
        dispatch(clearValidationError({ recordId, messageId, fieldName }));
      }
    }
  }, [selectedDate, fieldDefinition, recordId, messageId, fieldName, dispatch]);

  const handleCopy = async () => {
    try {
      const previewFormattedDate =
        value && safeDateParse(value)
          ? safeDateParse(value).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })
          : '-- None --';
      await navigator.clipboard.writeText(previewFormattedDate);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const compositeKey = `${messageId}_${recordId}`;

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const rawValue = date ? formatDateAsYMD(date) : null;
    const formattedDisplayValue = date
      ? date.toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })
      : '-- None --';
    onFieldChange(fieldName, rawValue, formattedDisplayValue);
  };

  const isFlashing = useSelector(
    (state) => !!state.flashFields[`${messageId}_${recordId}`]?.[fieldName]
  );
  const validationError = useSelector(
    (state) =>
      state.validation.validationsByCompositeKey[compositeKey]?.issues[fieldName]
  );

  if (!isEditing || !isUpdateable) {
    const labelClass = `mr-1 ${isChanged ? 'text-indigo-500' : ''}`;
    const previewFormattedDate =
      value && safeDateParse(value)
        ? safeDateParse(value).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
        : '-- None --';
    const content = (
      <>
        <strong className={labelClass}>{label}:</strong>
        <span className="truncate overflow-hidden whitespace-nowrap text-ellipsis flex-1">
          {previewFormattedDate}
        </span>
        <button
          onClick={handleCopy}
          aria-label="Copy field value"
          className="inline-flex items-center ml-1 opacity-0 group-hover:opacity-100 transition-opacity flex-none"
        >
          {copied ? (
            <CheckIcon className="h-4 w-4 text-indigo-600" />
          ) : (
            <DocumentDuplicateIcon className="h-3 w-3 text-gray-300 hover:text-indigo-600" />
          )}
        </button>
      </>
    );
    const containerClasses = `flex flex-col items-start ${isFlashing ? 'text-indigo-600' : 'text-gray-600'}`;
    return (
      <div className={containerClasses}>
        {isFlashing ? (
          <motion.div
            className="w-full"
            initial={{ opacity: 0.7 }}
            animate={{ opacity: [0.7, 1, 0.7] }}
            transition={{ duration: 2.0, repeat: Infinity, ease: 'easeInOut' }}
          >
            <div className="flex items-center w-full">{content}</div>
          </motion.div>
        ) : (
          <div className="flex items-center w-full">{content}</div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center text-xs sm:text-xsm md:text-xsm lg:text-xsm text-gray-600 w-full">
        <strong className={`mr-1 ${validationError ? 'text-red-500' : isChanged ? 'text-indigo-600' : ''}`}>
          {label}:
        </strong>
        <div className="relative flex-1">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select a date"
            portalId="root"
            className={`relative w-full cursor-pointer rounded border ${isChanged ? 'border-indigo-600' : 'border-gray-300'} px-2 py-1 text-left text-gray-700 focus:outline-none focus:border-indigo-600 bg-[#F2F0F8] font-sans`}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
              <div className="flex items-center justify-between px-3 bg-[#F2F0F8] text-gray-800 rounded-t">
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="p-1 rounded hover:bg-gray-200 disabled:opacity-50">
                  <ChevronLeftIcon className="h-5 w-5 text-indigo-700" />
                </button>
                <span className="font-semibold">
                  {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}
                </span>
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="p-1 rounded hover:bg-gray-200 disabled:opacity-50">
                  <ChevronRightIcon className="h-5 w-5 text-indigo-600" />
                </button>
              </div>
            )}
          />
        </div>
      </div>
      {validationError && <small className="text-red-500 text-xs mt-1">{validationError}</small>}
    </div>
  );
}