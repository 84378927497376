import ChatContainer from "./components/container/ChatContainer";
import { Provider } from "react-redux";
import store from "./redux/store";
import ChatHeader from "./components/header/ChatHeader.jsx";
import FakeBrowserWindow from "./fakeBrowserWindow.jsx";
import LottieAnimation from "../ui/LottieAnimation.js";
import LottieAnimationSmall from "../ui/LottieAnimationSmall.js";
import ChatHeroStyles from "./ChatHeroStyles";
import { 
  MagnifyingGlassIcon, 
PlusCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  PencilSquareIcon 
} from '@heroicons/react/20/solid';

export default function ChatHero() {
  return (
    <div id="product-hero" className="relative isolate overflow-hidden">
      <ChatHeroStyles />
      <div className="mx-auto w-full max-w-[1400px] px-6 pt-12 sm:pt-16 md:pt-24 lg:pt-36 lg:px-8 md-lg:flex lg:flex">
        <div className="w-full lg:w-1/2 mt-16 ml-10 sm:ml-6 md:ml-6 lg:ml-8">
          <h1 className="text-pretty text-6xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
            Chat with<br />
            <span className="text-transparent bg-clip-text bg-blue-gradient">Salesforce</span>.
          </h1>
          <p className="mt-8 text-pretty text-lg font-medium text-gray-600 sm:text-xl/8">
            <span className="block sm:inline">
              <span className="bg-gradient-to-r from-pink-500/70 to-pink-600/90 bg-clip-text text-transparent font-bold">
                ChatGPT
              </span> for your Salesforce.
            </span>{" "}
            <span className="text-indigo-600">Set up in one click.</span>
          </p>
          {/* Bullet List */}
          <ul className="mt-8 -ml-4 sm:ml-4 space-y-3 text-gray-600 sm:text-lg">
            <li className="flex items-center">
              <MagnifyingGlassIcon className="h-5 w-5 text-indigo-600 mr-3" />
              <span>Ask any question about a record.</span>
            </li>
            <li className="flex items-center">
              <PlusCircleIcon className="h-5 w-5 text-indigo-600 mr-3" />
              <span>Create a new record in seconds.</span>
            </li>
            <li className="flex items-center">
              <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-indigo-600 mr-3" />
              <span>Update your records conversationally.</span>
            </li>
            <li className="flex items-center">
              <PencilSquareIcon className="h-5 w-5 text-indigo-600 mr-3" />
              <span>Edit a record directly in the chat.</span>
            </li>
          </ul>
          {/* Lottie Animation & Login Button */}
          <span className="inline-flex items-center mt-16 -ml-11 sm:ml-0 md:ml-0">
            <div className="mr-2">
              <div className="block md:hidden">
                <LottieAnimationSmall visible={true} />
              </div>
              <div className="hidden md:block">
                <LottieAnimation visible={true} />
              </div>
            </div>
            <button
              onClick={() => (window.location.href = 'https://chat.tarn.co/')}
              className="shiny-button flex items-center gap-3 rounded-md bg-white px-3 py-3 mr-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              style={{ maxWidth: '300px' }}
            >
              <img
                src="/assets/Salesforce_Corporate_Logo_RGB.png"
                alt="Salesforce Logo"
                className="h-5 w-auto ml-1"
              />
              <span>Login with Salesforce</span>
            </button>
            <span className="font-semibold mx-1">and</span>
            <span className="font-semibold italic">go.</span>
          </span>
        </div>

        <div className="w-full md-lg:w-1/2 lg:w-1/2 mt-16 md:mt-16 lg:mt-8 md-lg:-translate-y-20 lg:-translate-y-20 lg:mr-8">
          <div className="mx-auto lg:min-w-[600px] max-w-[700px]">
            <FakeBrowserWindow>
              <Provider store={store}>
                <ChatHeader />
                <ChatContainer />
              </Provider>
            </FakeBrowserWindow>
          </div>
        </div>
      </div>
    </div>
  );
}