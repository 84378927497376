import store from './redux/store';
import { 
    addUserMessage, 
    addSystemMessage, 
    updateMessageById, 
    addToFieldUpdates 
} from './redux/slices/sessionSlice';
import reduxStates from './reduxStates.json';
import { setChatSession } from './redux/slices/sessionSlice';
import { setChatObjects } from './redux/slices/chatObjectsSlice';
import { setChatUserObjects } from './redux/slices/chatUserObjects';
import { setOrganization } from './redux/slices/organizationSlice';
import { setUser } from './redux/slices/userSlice';
import { setGenerating, setGeneratingText } from './redux/slices/generatingSlice';
import { setDemoRunning } from './redux/slices/uiSlice';
import templateScript from './templateScript';
import forgeBakeryCreate from './forgeBakeryCreate';
import tarnAppsUpdate from './tarnAppsUpdate';
import { v4 as uuidv4 } from 'uuid';

const demoScript = async () => {
    console.log('demo script hit');
    // Set the demo running state to true
    store.dispatch(setDemoRunning(true));
    try {
        store.dispatch(setChatSession(reduxStates.chat.chatSession));
        store.dispatch(setGenerating(reduxStates.generating.isGenerating));
        store.dispatch(setGeneratingText(reduxStates.generating.generatingText));
        store.dispatch(setChatObjects(reduxStates.chatObjects.chatObjects));
        store.dispatch(setChatUserObjects(reduxStates.chatUserObjects.chatUserObjects));
        store.dispatch(setOrganization(reduxStates.organization.organization));
        store.dispatch(setUser(reduxStates.user.user));

        await new Promise(resolve => setTimeout(resolve, 500));

        await forgeBakeryCreate();

        await new Promise(resolve => setTimeout(resolve, 3000));

        await tarnAppsUpdate();

        const systemMessage3 = {
            messageId: uuidv4(),
            sender: 'system',
            type: 'text',
            timestamp: new Date().toISOString(),
            content: `This is a lightweight demo of the chat - feel free to click around!`,
            credits: 0
        };
    
        store.dispatch(addSystemMessage(systemMessage3));

    } finally {
        // Clear the demo running state at the end
        store.dispatch(setDemoRunning(false));
    }
};

export default demoScript;