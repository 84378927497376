import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ArrowUpCircleIcon, StopCircleIcon } from '@heroicons/react/24/solid';
import demoScript from '../../../demoScript.js';

const ChatInput = () => {
  const isGenerating = useSelector((state) => state.generating.isGenerating);
  const editingRecords = useSelector((state) => state.isEditing.editingRecords);
  const isEditing = Object.keys(editingRecords).length > 0;
  const demoRunning = useSelector((state) => state.ui.demoRunning);
  const typerText = useSelector((state) => state.userMessageTyper.text);

  // Local state to animate the placeholder text
  const [displayedText, setDisplayedText] = useState('Message tarnChat');
  const typingSpeed = 10; // milliseconds per character
  const timeoutsRef = useRef([]);

  useEffect(() => {
    // Clear any existing timeouts
    timeoutsRef.current.forEach((id) => clearTimeout(id));
    timeoutsRef.current = [];

    if (typerText) {
      setDisplayedText('');
      for (let i = 0; i < typerText.length; i++) {
        const timeoutId = setTimeout(() => {
          setDisplayedText((prev) => prev + typerText.charAt(i));
        }, i * typingSpeed);
        timeoutsRef.current.push(timeoutId);
      }
    } else {
      setDisplayedText('Message tarnChat');
    }

    return () => {
      timeoutsRef.current.forEach((id) => clearTimeout(id));
      timeoutsRef.current = [];
    };
  }, [typerText]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleDemo = async () => {
    if (demoRunning) return;
    console.log('Demo button clicked');
    await demoScript();
  };

  // Conditionally set the text color based on the displayed text.
  const textColorClass = displayedText === 'Message tarnChat' ? 'text-gray-400' : 'text-gray-900';

  // If the Run the Demo overlay button is present (demoRunning is false),
  // force the arrow up icon to be gray.
  const sendButtonClass = !demoRunning 
    ? 'text-gray-500' 
    : (isGenerating || isEditing 
        ? 'text-gray-800 hover:text-gray-900 pointer-events-none' 
        : 'text-indigo-500 hover:text-indigo-700');

  return (
    <div className="relative">
      <div className="relative flex items-center px-3 pb-3">
        <textarea
          className={`flex-grow p-3 pr-16 h-24 iphone:h-12 rounded-lg resize-none outline-none border border-gray-300 bg-gray-200 font-sans text-sm placeholder-gray-300 ${textColorClass}`}
          placeholder="Message tarnChat"
          value={displayedText}
          onKeyDown={handleKeyDown}
          disabled
        />
        <button
          onClick={isGenerating || isEditing ? undefined : () => {}}
          className={`absolute right-4 bottom-4 ${sendButtonClass}`}
        >
          {isGenerating || isEditing ? (
            <StopCircleIcon className="h-10 w-10" />
          ) : (
            <ArrowUpCircleIcon className="h-10 w-10" />
          )}
        </button>
      </div>
      {/* Overlay Restart Demo button positioned in the center of the text area */}
      {!demoRunning && (
        <button
          onClick={handleDemo}
          className="absolute left-1/2 top-1/2 iphone:top-[calc(50%-6px)] transform -translate-x-1/2 -translate-y-1/2 z-10 bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 text-sm rounded"
        >
          Restart the Demo
        </button>
      )}
    </div>
  );
};

export default ChatInput;