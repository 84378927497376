import React, { useRef, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import RecordCard from './message-components/record-card/RecordCard.jsx';
import UserTextCard from './message-components/UserTextCard.jsx';
import SystemTextCard from './message-components/SystemTextCard.jsx';
import ReasoningCard from './message-components/ReasoningCard.jsx';

const MessageThread = ({ onAtBottomChange }) => {
  const messages = useSelector((state) => state.chat.chatSession.messages);
  const isGenerating = useSelector((state) => state.generating.isGenerating);

  const containerRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [prevMessageCount, setPrevMessageCount] = useState(messages.length);
  const [newSystemMessages, setNewSystemMessages] = useState(new Set());

  useEffect(() => {
    if (messages.length > prevMessageCount) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.sender === 'system') {
        setNewSystemMessages((prev) =>
          new Set([...prev, lastMessage.messageId || messages.length - 1])
        );
      }
    }
    setPrevMessageCount(messages.length);
  }, [messages, prevMessageCount]);

  const handleScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (scrollHeight <= clientHeight) {
      setIsAtBottom(true);
      if (onAtBottomChange) onAtBottomChange(true);
      return;
    }

    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
    const atBottom = distanceFromBottom < 50;
    setIsAtBottom(atBottom);
    if (onAtBottomChange) onAtBottomChange(atBottom);
  };

  useEffect(() => {
    const finalScrollTimeout = setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
      }
    }, 100);
    return () => clearTimeout(finalScrollTimeout);
  }, [messages]);

  useEffect(() => {
    if (!containerRef.current) return;
    if (isAtBottom) {
      containerRef.current.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [messages, isGenerating, isAtBottom]);

  // New effect: Every half second, if isGenerating is true and the container isn't at the bottom,
  // scroll down to include the ReasoningCard.
  useEffect(() => {
    if (!isGenerating) return;
    const interval = setInterval(() => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
        if (distanceFromBottom > 50) {
          containerRef.current.scrollTo({ top: scrollHeight, behavior: 'smooth' });
        }
      }
    }, 500);
    return () => clearInterval(interval);
  }, [isGenerating]);

  useEffect(() => {
    const currentRef = containerRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      return () => currentRef.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const lastUserMessageIndex = messages.reduce((lastIndex, msg, idx) => {
    return msg.type === 'text' && msg.sender === 'user' ? idx : lastIndex;
  }, -1);

  const lastRecordMessageIndex = messages.reduce((lastIndex, msg, idx) => {
    return msg.type === 'record' ? idx : lastIndex;
  }, -1);

  return (
    <div className="relative h-full w-full">
      <div className="absolute top-0 left-0 right-0 h-6 bg-gradient-to-b from-gray-100 to-transparent pointer-events-none z-10"></div>

      <div ref={containerRef} className="relative flex flex-col py-4 iphone:px-2 px-4 h-full overflow-y-auto">
        <div className="flex-1">
          {messages.map((msg, index) => {
            const shouldAnimate = newSystemMessages.has(msg.messageId || index);

            if (msg.type === 'record') {
              const isLastRecord = index === lastRecordMessageIndex;
              return (
                <div key={msg.messageId || index}>
                  {isLastRecord ? (
                    <motion.div
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                    >
                      <RecordCard
                        content={msg.content}
                        timestamp={msg.timestamp}
                        model={msg.model}
                        credits={msg.credits}
                        messageId={msg.messageId}
                      />
                    </motion.div>
                  ) : (
                    <RecordCard
                      content={msg.content}
                      timestamp={msg.timestamp}
                      model={msg.model}
                      credits={msg.credits}
                      messageId={msg.messageId}
                    />
                  )}
                </div>
              );
            }

            if (msg.type === 'text') {
              const isUser = msg.sender === 'user';
              if (isUser) {
                const isNewestUser = index === lastUserMessageIndex;
                return (
                  <div key={msg.messageId || index}>
                    {isNewestUser ? (
                      <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                      >
                        <UserTextCard
                          content={msg.content}
                          timestamp={msg.timestamp}
                          model={msg.model}
                          categorized={msg.categorized}
                          credits={msg.credits}
                        />
                      </motion.div>
                    ) : (
                      <UserTextCard
                        content={msg.content}
                        timestamp={msg.timestamp}
                        model={msg.model}
                        categorized={msg.categorized}
                        credits={msg.credits}
                      />
                    )}
                  </div>
                );
              } else {
                return (
                  <div key={msg.messageId || index}>
                    <SystemTextCard
                      content={msg.content}
                      timestamp={msg.timestamp}
                      model={msg.model}
                      credits={msg.credits}
                      shouldAnimate={shouldAnimate}
                    />
                  </div>
                );
              }
            }

            return (
              <div key={msg.messageId || index} className="text-xs text-red-500 self-start">
                Unknown message type: {msg.type}
              </div>
            );
          })}

          <AnimatePresence>
            {isGenerating && (
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 0, opacity: 0, transition: { duration: 0 } }}
                transition={{ type: 'spring', stiffness: 300, damping: 20, delay: 0.5 }}
              >
                <ReasoningCard />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default MessageThread;