import React, { useState } from 'react'
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'        // <-- added
import { motion } from 'framer-motion'           // <-- added

export default function ReferenceFieldPreview({
  fieldName,
label,
  value,
  isChanged,
  relatedMatch,
  showRelatedRecords,
  setShowRelatedRecords,
  recordId,
  messageId
}) {
  const [copied, setCopied] = useState(false)

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(String(value || ''))
      setCopied(true)
      setTimeout(() => setCopied(false), 5000)
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  const handleClickRelated = () => {
    if (relatedMatch) {
      setShowRelatedRecords(!showRelatedRecords)
    }
  }

  const isFlashing = useSelector((state) => !!state.flashFields[`${messageId}_${recordId}`]?.[fieldName]);

  const content = (
    <>
      <strong className={`mr-1 ${isChanged ? 'text-indigo-600' : ''}`}>{label}:</strong>

      {relatedMatch ? (
        <button
          type="button"
          onClick={handleClickRelated}
          className="text-indigo-600 hover:text-indigo-800 truncate overflow-hidden whitespace-nowrap text-ellipsis flex-1 text-left"
        >
          {value}
        </button>
      ) : (
        <span className="truncate overflow-hidden whitespace-nowrap text-ellipsis flex-1">
          {value}
        </span>
      )}

      <button
        onClick={handleCopy}
        aria-label="Copy field value"
        className="inline-flex items-center ml-1 opacity-0 group-hover:opacity-100 transition-opacity flex-none"
      >
        {copied ? (
          <CheckIcon className="h-4 w-4 text-indigo-600" />
        ) : (
          <DocumentDuplicateIcon className="h-3 w-3 text-gray-300 hover:text-indigo-600" />
        )}
      </button>
    </>
  )

  return isFlashing ? (
    <motion.div
      className="relative group text-xs sm:text-xsm md:text-xsm lg:text-xsm flex items-center max-w-full text-indigo-600"
      initial={{ opacity: 0.7 }}
      animate={{ opacity: [0.7, 1, 0.7] }}
      transition={{ duration: 2.0, repeat: Infinity, ease: 'easeInOut' }}
    >
      {content}
    </motion.div>
  ) : (
    <div className="relative group text-xs sm:text-xsm md:text-xsm lg:text-xsm text-gray-600 flex items-center max-w-full">
      {content}
    </div>
  )
}