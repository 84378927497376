import React from 'react';
import CustomHubspotForm from '../components/CustomHubspotForm.js'; // Adjust the path as needed
import BackgroundGradient from '../components/ui/BackgroundGradient.jsx'; // Adjust the path as needed
import ProductBrands from './landing/ProductBrands.jsx'; // Adjust the path as needed
import StackedBackgroundGradient from '../components/ui/StackedBackgroundGradient.jsx';
import ProductHero from './product/ProductHero.jsx';
import HowItWorks from './product/HowItWorks.jsx';

function HeroSection() {
  return (
    <div className="relative isolate pt-14">
      {/* Use BackgroundGradient component */}
      <BackgroundGradient />

      <div className="pt-24 sm:pt-32 lg:pb-30">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Approachable <span className="text-transparent bg-clip-text bg-pink-gradient">AI</span> tools for{' '}
              <span className="text-transparent bg-clip-text bg-blue-gradient">Salesforce</span>
            </h1>
            <p className="mt-10 text-lg leading-8 text-gray-600">
              <strong>Ignite</strong> your Salesforce experience with artificial intelligence.
            </p>

            {/* Add the ProductBrands component */}
          </div>

        </div>
      </div>
      <ProductBrands />
          <StackedBackgroundGradient />
          <ProductHero />
          <HowItWorks />
    </div>
  );
}

export default HeroSection;