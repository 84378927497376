import React, { Fragment } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { 
  Cog8ToothIcon, 
  UserCircleIcon, 
  BanknotesIcon, 
  TrashIcon, 
  ArrowRightEndOnRectangleIcon 
} from '@heroicons/react/24/solid';
import { useSelector, useDispatch } from 'react-redux';
import { setGeneratingText, setGenerating } from '../../redux/slices/generatingSlice.js';
import { setBuyCreditsOpen } from '../../redux/slices/uiSlice';

export default function SettingsMenu() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const handleClearChat = () => {
    // Simulate clear chat functionality
  };

  const handleLogout = () => {
    // Logout functionality is disabled; nothing happens
    console.log('Logout functionality is disabled in front-end only mode.');
  };

  const creditBalance = user?.creditBalance?.$numberDecimal
    ? parseFloat(user.creditBalance.$numberDecimal).toFixed(3)
    : '0.000';

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="focus:outline-none">
          <Cog8ToothIcon
            className="h-8 w-8 text-indigo-600 hover:text-indigo-800 cursor-pointer mr-8 mt-2"
            aria-hidden="true"
          />
        </MenuButton>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-30 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <MenuItem>
              {({ active }) => (
                <button
                  onClick={() => {}}
                  className={`flex items-center w-full text-left px-4 py-2 text-sm ${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  }`}
                >
                  <UserCircleIcon className="h-5 w-5 mr-2" />
                  Account Settings
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <button
                  onClick={() => dispatch(setBuyCreditsOpen(true))}
                  className={`flex items-center w-full text-left px-4 py-2 text-sm ${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  }`}
                >
                  <BanknotesIcon className="h-5 w-5 mr-2" />
                  Credits: <span className="font-semibold ml-1">{creditBalance}</span>
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <button
                  onClick={handleClearChat}
                  className={`flex items-center w-full text-left px-4 py-2 text-sm ${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  }`}
                >
                  <TrashIcon className="h-5 w-5 mr-2" />
                  Clear Chat
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <button
                  onClick={handleLogout}
                  className={`flex items-center w-full text-left px-4 py-2 text-sm ${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  }`}
                >
                  <ArrowRightEndOnRectangleIcon className="h-5 w-5 mr-2" />
                  Logout
                </button>
              )}
            </MenuItem>
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}