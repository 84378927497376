import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import HeroSection from './archive/HeroSection.js';
import ReleaseNotes from './components/ReleaseNotes.jsx';
import Testing from './components/Testing.jsx';
import Pricing from './components/pricing/Pricing.jsx';
import Team from './components/about/Team.jsx';
import TermsConditions from './components/legal/TermsConditions.jsx';
import Product from './archive/product/Product.jsx';
import DataPolicy from './components/legal/DataPolicy.jsx';
import { Analytics } from '@vercel/analytics/react';
import ChatProduct from './components/demo/ChatProduct.jsx';
import demoScript from './components/demo/demoScript.js';

function App() {
  const demoScriptRun = useRef(false);

  useEffect(() => {
    if (!demoScriptRun.current) {
      demoScriptRun.current = true;
      demoScript();
    }
  }, []);

  return (
    <Router>
      <div className="bg-white">
        <Header />
        <Routes>
          <Route path="/" element={<ChatProduct />}  />
          <Route path="/product" element={<ChatProduct />} />
          <Route path="/chat" element={<ChatProduct />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/team" element={<Team />} />
          <Route path="/release-notes" element={<ReleaseNotes />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/data-policy" element={<DataPolicy />} />
          <Route path="/testing" element={<Testing />} />
        </Routes>
        <Footer />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;