import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editingRecords: {}
};

const isEditingSlice = createSlice({
  name: 'isEditing',
  initialState,
  reducers: {
    startEditing: (state, action) => {
      const { recordId, recordType, messageId, type = 'update' } = action.payload;
      const key = `${messageId}_${recordId}`;
      state.editingRecords[key] = { recordId, recordType, messageId, isEditing: true, type };
    },
    stopEditing: (state, action) => {
      const { recordId, messageId } = action.payload;
      const key = `${messageId}_${recordId}`;
      delete state.editingRecords[key];
    },
    clearAllEditing: (state) => {
      state.editingRecords = {};
    }
  },
});

export const { startEditing, stopEditing, clearAllEditing } = isEditingSlice.actions;
export default isEditingSlice.reducer;