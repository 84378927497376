import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  onboarding: false,
  startingUp: true,
  negativeCredits: false,
  isBuyCreditsOpen: false,
  previewSending: false, // NEW STATE PROPERTY
  demoRunning: false,    // NEW STATE PROPERTY
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setOnboarding(state, action) {
      state.onboarding = action.payload;
    },
    setStartingUp(state, action) {
      state.startingUp = action.payload;
    },
    setNegativeCredits(state, action) {
      state.negativeCredits = action.payload;
    },
    setBuyCreditsOpen(state, action) {
      state.isBuyCreditsOpen = action.payload;
    },
    setPreviewSending(state, action) {
      state.previewSending = action.payload;
    },
    setDemoRunning(state, action) {  // NEW REDUCER
      state.demoRunning = action.payload;
    },
  },
});

export const {
  setOnboarding,
  setStartingUp,
  setNegativeCredits,
  setBuyCreditsOpen,
  setPreviewSending,
  setDemoRunning,
} = uiSlice.actions;

export default uiSlice.reducer;