import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './slices/sessionSlice.js';
import modelReducer from './slices/modelSlice.js';
import generatingReducer from './slices/generatingSlice.js';
import websocketReducer from './slices/websocketSlice.js';
import chatObjectsReducer from './slices/chatObjectsSlice.js';
import userReducer from './slices/userSlice.js';
import organizationReducer from './slices/organizationSlice.js';
import isEditingReducer from './slices/isEditingSlice.js';
import draftRecordReducer from './slices/draftRecordSlice.js';
import flashFieldsReducer from './slices/flashFieldsSlice.js';
import validationReducer from './slices/validationSlice.js';
import chatUserObjectsReducer from './slices/chatUserObjects.js';
import uiReducer from './slices/uiSlice.js';
import userMessageTyperReducer from './slices/userMessageTyperSlice.js'; // Import the new slice

const store = configureStore({
  reducer: {
    chat: chatReducer,
    model: modelReducer,
    generating: generatingReducer,
    websocket: websocketReducer,
    chatObjects: chatObjectsReducer,
    chatUserObjects: chatUserObjectsReducer,
    user: userReducer,
    organization: organizationReducer,
    isEditing: isEditingReducer,
    draftRecord: draftRecordReducer,
    flashFields: flashFieldsReducer,
    validation: validationReducer,
    ui: uiReducer,
    userMessageTyper: userMessageTyperReducer, // Add the new reducer here
  },
});

export default store;