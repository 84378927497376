import store from './redux/store';
import { 
    addUserMessage, 
    addSystemMessage, 
    updateMessageById, 
    addToFieldUpdates 
} from './redux/slices/sessionSlice';
import { setUserMessageTyperText, clearUserMessageTyperText } from './redux/slices/userMessageTyperSlice';
import { setGenerating, setGeneratingText } from './redux/slices/generatingSlice';
import { flashFields } from './redux/slices/flashFieldsSlice';
import { startEditing, stopEditing } from './redux/slices/isEditingSlice';
import { initializeDraft, updateDraftField, clearDraft } from './redux/slices/draftRecordSlice';
import { setPreviewSending } from './redux/slices/uiSlice';
import { v4 as uuidv4 } from 'uuid';

const forgeBakeryCreate = async () => {

    const promptText = `create a new biz opportunity on the tarn apps account called Forge Bakery - Renewal - 500 credits. close date is June 7, stage is present, next step is proposal, and amount is 400`
    store.dispatch(setUserMessageTyperText(promptText));
    await new Promise(resolve => setTimeout(resolve, 2500));
    store.dispatch(clearUserMessageTyperText());

    const userMessage1 = {
        messageId: uuidv4(),
        sender: 'user',
        type: 'text',
        timestamp: new Date().toISOString(),
        content: promptText,
        credits: 0.008
    };

    store.dispatch(addUserMessage(userMessage1));

    store.dispatch(setGenerating(true));
    store.dispatch(setGeneratingText('Reasoning'));

    await new Promise(resolve => setTimeout(resolve, 3000));

    const systemMessage1 = {
        messageId: uuidv4(),
        sender: 'system',
        type: 'text',
        timestamp: new Date().toISOString(),
        content: `Got it - let's create that opportunity`,
        credits: 0
    };

    store.dispatch(addSystemMessage(systemMessage1));

    await new Promise(resolve => setTimeout(resolve, 3000));

    store.dispatch(setGeneratingText('Drafting opportunity'));

    const initialRecord = {
        attributes: {
            type: 'Opportunity',
            url: null
        },
        AccountId: null,
        Amount: null,
        CloseDate: null,
        Id: uuidv4(),
        LastModifiedDate: null,
        Name: null,
        NextStep: null,
        Probability: null,
        StageName: null,
        Type: null,
    };

    const systemMessage2 = {
        messageId: uuidv4(),
        sender: 'system',
        type: 'record',
        timestamp: new Date().toISOString(),
        credits: 0.012,
        content: initialRecord
    };

    store.dispatch(addSystemMessage(systemMessage2));

    store.dispatch(startEditing({
        recordId: systemMessage2.content.Id,
        recordType: systemMessage2.content.attributes.type,
        messageId: systemMessage2.messageId,
        type: 'create'
    }));

    const { attributes, Id, ...initialValues } = systemMessage2.content;
    store.dispatch(initializeDraft({
        recordId: Id,
        messageId: systemMessage2.messageId,
        initialValues: initialValues
    }));

    await new Promise(resolve => setTimeout(resolve, 100));

    const fieldUpdatesArray = [
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'Name',
          newValue: 'Forge Bakery - Renewal - 500 credits',
          displayName: 'Forge Bakery - Renewal - 500 credits'
        },
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'StageName',
          newValue: 'Meet & Present'
        },
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'CloseDate',
          newValue: '2025-06-07',
          displayName: '2025-06-07'
        },
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'NextStep',
          newValue: 'proposal',
          displayName: 'Send a pricing proposal'
        },
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'Amount',
          newValue: '400',
        },
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'AccountId',
          newValue: '001fJ00001OCxHJQA1',
          displayName: 'Tarn Apps Inc.'
        },
        {
            recordId: Id,
            messageId: systemMessage2.messageId,
            fieldName: 'Probability',
            newValue: '30',
          },
          {
            recordId: Id,
            messageId: systemMessage2.messageId,
            fieldName: 'Type',
            newValue: 'New Business',
          }
      ];
      
      fieldUpdatesArray.forEach(update => {
        store.dispatch(updateDraftField(update));
      });

      store.dispatch(setGenerating(false));

    await new Promise(resolve => setTimeout(resolve, 3000));

    const updatedRecord = {
        attributes: {
            type: 'Opportunity',
            url: null
        },
        AccountId: '001fJ00001OCxHJQA1',
        Amount: 400,
        CloseDate: '2025-06-07',
        Id: initialRecord.Id,
        LastModifiedDate: Date.now(),
        Name: 'Forge Bakery - Renewal - 500 credits',
        NextStep: 'Send a pricing proposal',
        Probability: 30,
        StageName: 'Meet & Present',
        Type: 'New Business',
        relatedRecords: [  {
            AnnualRevenue: 1003,
            Id: "001fJ00001OCxHJQA1",
            Industry: "Technology",
            LastModifiedDate: "2025-02-21T22:17:52.000+0000",
            Name: "Tarn Apps Inc.",
            NumberOfEmployees: 1,
            Phone: "7745718190",
            Website: "https://tarn.co/",
            attributes: {
              type: "Account",
              url: "/services/data/v59.0/sobjects/Account/001fJ00001OCxHJQA1"
            }
          }]
    };

    const fieldUpdates = {
        recordId: updatedRecord.Id,
        recordType: updatedRecord.attributes.type,
        messageId: systemMessage2.messageId,
        displayName: 'Forge Bakery',
        fieldUpdates: [
            {
                fieldName: "Name",
                oldValue: initialRecord.Name,
                newValue: updatedRecord.Name,
                displayName: 'Forge Bakery - Renewal - 500 credits',
                timestamp: Date.now(),
            },
            {
                fieldName: "StageName",
                oldValue: initialRecord.StageName,
                newValue: updatedRecord.StageName,
                timestamp: Date.now(),
            },
            {
                fieldName: "CloseDate",
                oldValue: initialRecord.CloseDate,
                newValue: updatedRecord.CloseDate,
                displayName: '2025-06-07',
                timestamp: Date.now(),
            },
            {
                fieldName: "NextStep",
                oldValue: initialRecord.NextStep,
                newValue: updatedRecord.NextStep,
                displayName: 'Send a pricing proposal',
                timestamp: Date.now(),
            },
            {
                fieldName: "Amount",
                oldValue: initialRecord.Amount,
                newValue: updatedRecord.Amount,
                timestamp: Date.now(),
            },
            {
                fieldName: "AccountId",
                oldValue: initialRecord.AccountId,
                newValue: updatedRecord.AccountId,
                displayName: 'Tarn Apps Inc.',
                timestamp: Date.now(),
            },
            {
                fieldName: "Probability",
                oldValue: initialRecord.Probability,
                newValue: updatedRecord.Probability,
                timestamp: Date.now(),
            },
            {
                fieldName: "Type",
                oldValue: initialRecord.Type,
                newValue: updatedRecord.Type,
                timestamp: Date.now(),
            }
        ],
    };

    store.dispatch(setPreviewSending(true));
    await new Promise(resolve => setTimeout(resolve, 700));
    store.dispatch(setPreviewSending(false));

    store.dispatch(updateMessageById({ messageId: systemMessage2.messageId, updates: { content: updatedRecord } }));
    store.dispatch(addToFieldUpdates(fieldUpdates));
    store.dispatch(clearDraft({ recordId: Id, messageId: systemMessage2.messageId }));
    store.dispatch(stopEditing({ recordId: Id, messageId: systemMessage2.messageId }));
    store.dispatch(flashFields({ 
        recordId: initialRecord.Id, 
        fields: [
            'Name', 
            'StageName', 
            'CloseDate', 
            'NextStep', 
            'Amount', 
            'AccountId', 
            'Probability', 
            'Type'
        ], 
        messageId: systemMessage2.messageId 
    }));

};

export default forgeBakeryCreate;