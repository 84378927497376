import store from './redux/store';
import { 
    addUserMessage, 
    addSystemMessage, 
    updateMessageById, 
    addToFieldUpdates 
} from './redux/slices/sessionSlice';
import { setUserMessageTyperText, clearUserMessageTyperText } from './redux/slices/userMessageTyperSlice';
import { setGenerating, setGeneratingText } from './redux/slices/generatingSlice';
import { flashFields } from './redux/slices/flashFieldsSlice';
import { startEditing, stopEditing } from './redux/slices/isEditingSlice';
import { initializeDraft, updateDraftField, clearDraft } from './redux/slices/draftRecordSlice';
import { setPreviewSending } from './redux/slices/uiSlice';
import { v4 as uuidv4 } from 'uuid';

const tarnAppsUpdate = async () => {

    const promptText = `change the owner of the tarn apps acct to Jane Smith and update the employee count to 1, the revenue to 200,000, the type to Prospect, and summarize this from their website for the description: 
    
Tarn Apps Inc. is building AI-powered tools that integrate with Salesforce to help businesses analyze, fix, and optimize their CRM data with ease. The company’s flagship product, tarnChat, is an intelligent assistant that enables users to retrieve, update, and manage Salesforce records through natural language conversations. With a consumption-based model, Tarn Apps ensures customers only pay for what they use, leveraging OpenAI and Salesforce APIs to streamline workflows and enhance productivity.`
    store.dispatch(setUserMessageTyperText(promptText));
    await new Promise(resolve => setTimeout(resolve, 3000));
    store.dispatch(clearUserMessageTyperText());

    const userMessage1 = {
        messageId: uuidv4(),
        sender: 'user',
        type: 'text',
        timestamp: new Date().toISOString(),
        content: promptText,
        credits: 0.008
    };

    store.dispatch(addUserMessage(userMessage1));

    store.dispatch(setGenerating(true));
    store.dispatch(setGeneratingText('Reasoning'));

    await new Promise(resolve => setTimeout(resolve, 3000));

    const systemMessage1 = {
        messageId: uuidv4(),
        sender: 'system',
        type: 'text',
        timestamp: new Date().toISOString(),
        content: `Got it - let's update the Tarn Apps account`,
        credits: 0
    };
    store.dispatch(addSystemMessage(systemMessage1));

    await new Promise(resolve => setTimeout(resolve, 2000));

    store.dispatch(setGeneratingText('Drafting updates'));
    
    const initialRecord = {
        attributes: {
            type: 'Account',
            url: '/services/data/v56.0/sobjects/Account/001fJ00001OCxHJQA1'
        },
        AnnualRevenue: null,
        Id: "001fJ00001OCxHJQA1",
        Industry: "Technology",
        LastModifiedDate: "2025-02-21T22:17:52.000+0000",
        Name: "Tarn Apps Inc.",
        NumberOfEmployees: null,
        OwnerId: "005fJ000000PFFCQA4",
        Phone: "7745718190",
        Type: null,
        Website: "https://tarn.co/",
        Description: null,
        OwnerId: '005fJ000000PFFCQA4',
        relatedRecords: [
            {
                attributes: {
                    type: 'User',
                    url: '/services/data/v56.0/sobjects/User/005fJ000000PFFCQA4'
                },
                Email: "josh@tarn.co",
                Id: "005fJ000000PFFCQA4",
                LastLoginDate: "2025-03-04T14:59:23.000+0000",
                LastModifiedDate: "2025-02-20T20:20:29.000+0000",
                Name: "Joshua Jackson",
                Phone: null,
                Title: null,
                Username: "josh-ec4l@force.com"
            }
        ]
    };

    const systemMessage2 = {
        messageId: uuidv4(),
        sender: 'system',
        type: 'record',
        timestamp: new Date().toISOString(),
        credits: 0.012,
        content: initialRecord
    };
    store.dispatch(addSystemMessage(systemMessage2));

    await new Promise(resolve => setTimeout(resolve, 3000));

    store.dispatch(startEditing({
        recordId: systemMessage2.content.Id,
        recordType: systemMessage2.content.attributes.type,
        messageId: systemMessage2.messageId
    }));

    const { attributes, Id, ...initialValues } = systemMessage2.content;
    store.dispatch(initializeDraft({
        recordId: Id,
        messageId: systemMessage2.messageId,
        initialValues: initialValues
    }));

    await new Promise(resolve => setTimeout(resolve, 100));

    const fieldUpdatesArray = [
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'AnnualRevenue',
          newValue: '200000'
        },
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'NumberOfEmployees',
          newValue: '1'
        },
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'OwnerId',
          newValue: '005fJ000000PFFCQA8',
          displayName: 'Jane Smith'
        },
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'Type',
          newValue: 'Prospect',
          displayName: 'Prospect'
        },
        {
          recordId: Id,
          messageId: systemMessage2.messageId,
          fieldName: 'Description',
          newValue: 'Tarn Apps Inc. builds AI-powered tools, including tarnChat, that leverage OpenAI and Salesforce APIs to help businesses optimize their CRM data.'
        }
      ];
      
      fieldUpdatesArray.forEach(update => {
        store.dispatch(updateDraftField(update));
      });

      store.dispatch(setGenerating(false));

    await new Promise(resolve => setTimeout(resolve, 3000));

    const updatedRecord = {
        attributes: {
            type: 'Account',
            url: '/services/data/v56.0/sobjects/Account/001fJ00001OCxHJQA1'
        },
        AnnualRevenue: 200000,
        Id: "001fJ00001OCxHJQA1",
        Industry: "Technology",
        LastModifiedDate: "2025-02-21T22:17:52.000+0000",
        Name: "Tarn Apps Inc.",
        NumberOfEmployees: 1,
        OwnerId: "005fJ000000PFFCQA8",
        Phone: "555-555-5555",
        Type: "Prospect",
        Website: "https://tarn.co/",
        Description: "Tarn Apps Inc. builds AI-powered tools, including tarnChat, that leverage OpenAI and Salesforce APIs to help businesses optimize their CRM data.",
        relatedRecords: [
            {
                attributes: {
                    type: 'User',
                    url: '/services/data/v56.0/sobjects/User/005fJ000000PFFCQA8'
                },
                Email: "jane@tarn.co",
                Id: "005fJ000000PFFCQA8",
                LastLoginDate: null,
                LastModifiedDate: null,
                Name: "Jane Smith",
                Phone: null,
                Title: null,
                Username: "jane@tarn.co"
            }
        ]
    };

    const fieldUpdates = {
        recordId: updatedRecord.Id,
        recordType: updatedRecord.attributes.type,
        messageId: systemMessage2.messageId,
        displayName: 'Tarn Apps Inc.',
        fieldUpdates: [
          {
            fieldName: "AnnualRevenue",
            oldValue: initialRecord.AnnualRevenue,
            newValue: updatedRecord.AnnualRevenue,
            timestamp: Date.now(),
          },
          {
            fieldName: "NumberOfEmployees",
            oldValue: initialRecord.NumberOfEmployees,
            newValue: updatedRecord.NumberOfEmployees,
            timestamp: Date.now(),
          },
          {
            fieldName: "OwnerId",
            oldValue: initialRecord.OwnerId,
            newValue: updatedRecord.OwnerId,
            displayName: "Jane Smith",
            timestamp: Date.now(),
          },
          {
            fieldName: "Type",
            oldValue: initialRecord.Type,
            newValue: updatedRecord.Type,
            displayName: "Prospect",
            timestamp: Date.now(),
          },
          {
            fieldName: "Description",
            oldValue: initialRecord.Description,
            newValue: updatedRecord.Description,
            timestamp: Date.now(),
          }
        ],
      };

    store.dispatch(setPreviewSending(true));
    await new Promise(resolve => setTimeout(resolve, 700));
    store.dispatch(setPreviewSending(false));

    store.dispatch(updateMessageById({ messageId: systemMessage2.messageId, updates: { content: updatedRecord } }));
    store.dispatch(addToFieldUpdates(fieldUpdates));
    store.dispatch(clearDraft({ recordId: Id, messageId: systemMessage2.messageId }));
    store.dispatch(stopEditing({ recordId: Id, messageId: systemMessage2.messageId }));
    store.dispatch(flashFields({
        recordId: initialRecord.Id,
        fields: [
          'AnnualRevenue',
          'NumberOfEmployees',
          'OwnerId',
          'Type',
          'Description'
        ],
        messageId: systemMessage2.messageId
      }));

};

export default tarnAppsUpdate;