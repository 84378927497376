import React, { useState, useEffect, useRef, Fragment } from 'react'
import { createPortal } from 'react-dom'
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, Transition } from '@headlessui/react'
import { CheckIcon as SolidCheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { tailspin } from 'ldrs'

// We receive `socket` directly if the parent decides to pass it in
export default function ReferenceFieldEdit({
    fieldName,
    label,
    value,              // The raw ID
    displayText,        // Friendly name based on relatedMatch, if any
    isChanged,
    getDisplayName,
    onFieldChange,
    recordType,
    socket
}) {
    tailspin.register()

    // Local state for the combobox
    const [searchQuery, setSearchQuery] = useState(displayText || '')
    const [searchResults, setSearchResults] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [typingTimer, setTypingTimer] = useState(null)
    const [hasFocused, setHasFocused] = useState(false)
    const [comboboxOpen, setComboboxOpen] = useState(false)
    const [position, setPosition] = useState({ top: 0, left: 0, width: 0 })
    const [selected, setSelected] = useState(null)

    // The container to measure so we can position the dropdown
    const containerRef = useRef(null)

    // If combobox closes, revert the search field to whatever our original display text was
    useEffect(() => {
        if (!comboboxOpen) {
            setSearchResults([])
            setSearchQuery(displayText || '')
        }
    }, [comboboxOpen, displayText])

    // Listen for search results from the server
    useEffect(() => {
        if (!socket) return

        const handleSearchResults = (results) => {
            const records = results?.searchRecords || []
            setSearchResults(records.slice(0, 5))
            setIsLoading(false)
        }

        socket.on('searchRefObjectResults', handleSearchResults)
        return () => {
            socket.off('searchRefObjectResults', handleSearchResults)
        }
    }, [socket])

    // If the combobox is opened, measure containerRef for positioning
    useEffect(() => {
        if (comboboxOpen && containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect()
            setPosition({
                top: rect.bottom,
                left: rect.left,
                width: rect.width
            })
        }
    }, [comboboxOpen])

    // Handle user’s typing in the input
    const handleComboboxQueryChange = (queryText) => {
        setSearchQuery(queryText)

        // If user cleared everything
        if (!queryText.trim()) {
            setSearchResults([])
            setComboboxOpen(false)
            setIsLoading(false)
            return
        }

        setIsLoading(true)
        if (typingTimer) clearTimeout(typingTimer)

        const newTimer = setTimeout(() => {
            if (socket) {
                socket.emit('searchReferenceObject', {
                    query: queryText,
                    fieldName,
                    recordType
                })
            }
        }, 500)

        setTypingTimer(newTimer)
    }

    const handleComboboxSelect = (selectedRecord) => {
        // If the user *did* select a valid record
        if (selectedRecord && selectedRecord.Id) {
          setSelected(selectedRecord)
          onFieldChange(fieldName, selectedRecord.Id, getDisplayName(selectedRecord))
          setSearchQuery(getDisplayName(selectedRecord))
          setSearchResults([])
          setComboboxOpen(false)
          return
        }
      
        // Otherwise, if no valid selection was made...
        if (value == null) {
          setSelected(null)
          onFieldChange(fieldName, null)
          setSearchQuery('')
        } else {
          // Revert to the original display value
          setSearchQuery(displayText)
        }
      
        setSearchResults([])
        setComboboxOpen(false)
      }

    return (
        <div className="relative group text-xs sm:text-xsm md:text-xsm lg:text-xsm text-gray-600 flex items-center max-w-full">
            <strong className={`mr-1 ${isChanged ? 'text-indigo-600' : ''}`}>{label}:</strong>

            <Combobox value={selected} onChange={handleComboboxSelect}>
                {({ open }) => {
                    // Keep local comboboxOpen in sync
                    if (open !== comboboxOpen) {
                        setComboboxOpen(open)
                    }

                    return (
                        <>
                            <div ref={containerRef} className="relative flex-1">
                                <ComboboxInput
                                    className={clsx(
                                        'w-full cursor-pointer rounded border',
                                        isChanged ? 'border-indigo-600' : 'border-gray-300',
                                        'bg-white py-1 px-2 text-gray-700 focus:outline-none'
                                    )}
                                    displayValue={(record) => (record ? getDisplayName(record) : searchQuery)}
                                    onFocus={() => {
                                        if (!hasFocused) {
                                            setHasFocused(true)
                                        }
                                    }}
                                    onChange={(e) => {
                                        setHasFocused(true)
                                        handleComboboxQueryChange(e.target.value)
                                    }}
                                    onKeyDown={(e) => {
                                        // If user backspaces the last char, close
                                        if (e.key === 'Backspace' && searchQuery.length === 1) {
                                            setSearchResults([])
                                            setComboboxOpen(false)
                                        }
                                    }}
                                    autoComplete="off"
                                    spellCheck={false}
                                    inputMode="search"
                                />

                                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                                </ComboboxButton>

                                {isLoading && (
                                    <div className="absolute top-1/2 right-9 -translate-y-1/2">
                                        <l-tailspin size="10" speed="2.5" color="#818CF8" />
                                    </div>
                                )}
                            </div>

                            {open && createPortal(
                                <Transition
                                    as={Fragment}
                                    show={open}
                                    enter="transition ease-out duration-100"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <ComboboxOptions
                                        className="max-h-60 overflow-auto rounded-md border border-gray-300 bg-white p-1 shadow-lg z-[9999]"
                                        style={{
                                            position: 'fixed',
                                            top: position.top,
                                            left: position.left,
                                            width: position.width
                                        }}
                                    >
                                        {searchResults.length > 0 ? (
                                            searchResults.map((record) => (
                                                <ComboboxOption
                                                    key={record.Id}
                                                    value={record}
                                                    className={({ active }) =>
                                                        clsx(
                                                            'cursor-pointer select-none rounded-md px-2 py-1 text-xsm',
                                                            active ? 'bg-indigo-600 text-white' : 'text-gray-700'
                                                        )
                                                    }
                                                >
                                                    {({ selected: isSelected }) => (
                                                        <div className="flex items-center gap-2">
                                                            <SolidCheckIcon
                                                                className={clsx('h-4 w-4', isSelected ? 'opacity-100' : 'opacity-0')}
                                                            />
                                                            <span>{getDisplayName(record)}</span>
                                                        </div>
                                                    )}
                                                </ComboboxOption>
                                            ))
                                        ) : (
                                            <div className="px-2 py-1 text-sm text-gray-500">No results</div>
                                        )}
                                    </ComboboxOptions>
                                </Transition>,
                                document.body
                            )}
                        </>
                    )
                }}
            </Combobox>
            {isLoading && (
                <div className="absolute top-1/2 right-9 -translate-y-1/2">
                    <l-tailspin size="10" speed="2.5" color="#818CF8" />
                </div>
            )}
        </div>
    )
}