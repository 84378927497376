export default function FakeBrowserWindow({ children, className }) {
    return (
      <div className={`rounded-lg shadow-xl overflow-hidden border border-gray-300 bg-white ${className}`}>
        <div className="flex items-center gap-1.5 p-2 bg-gradient-to-tr from-[#ff80b5]/15 to-[#9089fc]/15 border-b border-gray-300">
          <span className="w-3 h-3 bg-red-400 rounded-full" />
          <span className="w-3 h-3 bg-yellow-400 rounded-full" />
          <span className="w-3 h-3 bg-green-400 rounded-full" />
        </div>
        <div className="bg-[#F3F3F3]">
          {children}
        </div>
      </div>
    );
  }