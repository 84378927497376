import { CheckIcon, BoltIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import BackgroundGradient from '../ui/BackgroundGradient.jsx';

const tiers = [
  {
    name: 'Starter',
    id: 'tier-starter',
    href: '#',
    price: '$15',
    description: "Get started. Get comfortable. We're confident you'll grow with us.",
    features: [
      '15 credits or ~600 messages',
      'Credit cost per message based on AI token usage',
      'Unlimited Salesforce record Creations & Updates - for free',
      'Dedicated encrypted database for your data',
      'Credits never expire'
    ],
    mostPopular: false,
  },
  {
    name: 'Lightning',
    id: 'tier-lightning',
    href: '#',
    price: '$40',
    description: "For when you're all in. Get 10 bonus credits and early access to new features!",
    features: [
      '40 credits + 10 bonus credits',
      '~2000 messages',
      'Unlimited Salesforce record Creations & Updates - for free',
      'Access to All Features',
      'Dedicated encrypted database for your data',
      'Credits never expire'
    ],
    mostPopular: true,
  },
  {
    name: 'Titan',
    id: 'tier-titan',
    href: '#',
    price: "For Business",
    description: "Tarn has entered the chat. Cut costs in the Salesforce complaint department by up to 50%.",
    features: [
      "Discounted Credit Packages",
      "Unlimited Users",
      "Prioritized Feature Requests",
      "Everything in Lightning"
    ],
    mostPopular: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Pricing() {
  return (
    <div className="relative isolate">
      {/* Background Gradient */}
      <BackgroundGradient />

      {/* Content */}
      <div className="pt-24 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
              Only pay for what you use
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg font-medium text-gray-600 sm:text-xl">
            <span className="text-indigo-600 font-bold">No subscriptions.</span> No commitments. <br /> Buy credits as needed. Track your credit usage in the app.
          </p>
          <div className="isolate mx-auto mt-16 grid max-w-lg grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {tiers.map((tier, tierIdx) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                  tierIdx === 0 ? 'lg:rounded-r-none' : '',
                  tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
                  'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 w-full'
                )}
              >
                <div>
                  <div className="flex items-center justify-between gap-x-4">
                    <h3
                      id={tier.id}
                      className={classNames(
                        tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                        'text-lg font-semibold flex items-center gap-x-2'
                      )}
                    >
                      {tier.name}
                      {tier.name === 'Lightning' && <BoltIcon className="h-5 w-5 text-indigo-600" />}
                    </h3>
                    {tier.mostPopular ? (
                      <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold text-indigo-600">
                        Most popular
                      </p>
                    ) : null}
                  </div>
                  <p className="mt-4 text-sm text-gray-600">{tier.description}</p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-semibold tracking-tight text-gray-900">{tier.price}</span>
                  </p>
                  <ul role="list" className="mt-8 space-y-3 text-sm text-gray-600">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3 items-center">
                        <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                        <span>{feature}</span>
                        {tier.name === 'Starter' && feature === '15 credits or ~600 messages' && (
                          <div className="relative group">
                            <InformationCircleIcon className="h-4 w-4 text-indigo-400 ml-1 cursor-pointer" />
                            <div className="absolute bottom-full mb-2 hidden w-64 p-2 text-xs text-gray-700 bg-white border border-gray-200 rounded shadow-lg z-50 group-hover:block group-hover:right-0 group-hover:left-auto">
                              <strong>15 credits</strong> translates to about <strong>600 messages</strong>, including messages sent and received.
                              <br /><br />**There is no additional cost when creating or updating a Salesforce record.
                            </div>
                          </div>
                        )}
                        {tier.name === 'Lightning' && feature === '~2000 messages' && (
                          <div className="relative group">
                            <InformationCircleIcon className="h-4 w-4 text-indigo-400 ml-1 cursor-pointer" />
                            <div className="absolute bottom-full mb-2 hidden w-64 p-2 text-xs text-gray-700 bg-white border border-gray-200 rounded shadow-lg z-50 group-hover:block group-hover:right-0 group-hover:left-auto">
                              <strong>50 credits</strong> translates to about <strong>2000 messages</strong>, including messages sent and received.
                              <br /><br />**There is no additional cost when creating or updating a Salesforce record.
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <a
                  href={tier.name === 'Titan' ? 'mailto:sales@tarn.co' : 'https://chat.tarn.co/'}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                      : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                    'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  )}
                >
                  {tier.name === 'Titan' ? "Let's Chat" : 'Buy Credits'}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}