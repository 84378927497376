import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline';

function safeDateParse(dateString) {
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? null : date;
}

export default function DateTimeField({
  fieldName,
  label,
  value,
  isChanged,
  recordId,
  messageId,
}) {
  const [copied, setCopied] = useState(false);
  const isFlashing = useSelector(
    (state) => !!state.flashFields[`${messageId}_${recordId}`]?.[fieldName]
  );

  // Parse the incoming value into a Date object
  const date = value ? safeDateParse(value) : null;

  // Format the date/time as: "Feb 27, 2025, 11:34AM EST"
  const formattedDate = date
    ? date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
      })
    : '-- None --';

  // Remove extra space between the minute and AM/PM (if present)
  const cleanedFormattedDate =
    formattedDate !== '-- None --'
      ? formattedDate.replace(/(\d{1,2}:\d{2})\s?(AM|PM)/, '$1$2')
      : formattedDate;

  // Split the string so the time zone (last word) can be styled separately
  let mainPart = cleanedFormattedDate;
  let timeZonePart = '';
  if (cleanedFormattedDate !== '-- None --') {
    const lastSpaceIndex = cleanedFormattedDate.lastIndexOf(' ');
    if (lastSpaceIndex !== -1) {
      mainPart = cleanedFormattedDate.substring(0, lastSpaceIndex);
      timeZonePart = cleanedFormattedDate.substring(lastSpaceIndex + 1);
    }
  }

  const handleCopy = async () => {
    try {
      // Copy the formatted datetime rather than the raw value
      const textToCopy = cleanedFormattedDate;
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  // Prevent label wrapping and allow the date to truncate with ellipses
  const content = (
    <>
      <strong className={`mr-1 ${isChanged ? 'text-indigo-500' : ''} whitespace-nowrap`}>
        {label}:
      </strong>
      <span className="ml-2 flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
        {mainPart}{' '}
        <span className="font-semibold">{timeZonePart}</span>
      </span>
      <button
        onClick={handleCopy}
        aria-label="Copy field value"
        className="inline-flex items-center ml-1 opacity-0 group-hover:opacity-100 transition-opacity flex-none"
      >
        {copied ? (
          <CheckIcon className="h-4 w-4 text-indigo-600" />
        ) : (
          <DocumentDuplicateIcon className="h-3 w-3 text-gray-300 hover:text-indigo-600" />
        )}
      </button>
    </>
  );

  const containerClasses = `flex items-center w-full ${
    isFlashing ? 'text-indigo-600' : 'text-gray-600'
  }`;

  return (
    <div className={containerClasses}>
      {isFlashing ? (
        <motion.div
          className="w-full"
          initial={{ opacity: 0.7 }}
          animate={{ opacity: [0.7, 1, 0.7] }}
          transition={{ duration: 2.0, repeat: Infinity, ease: 'easeInOut' }}
        >
          {content}
        </motion.div>
      ) : (
        content
      )}
    </div>
  );
}