import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline';
import { clearValidationError, setValidationError } from '../../../../../../redux/slices/validationSlice.js';

function parseNumber(value, fieldType) {
  if (value === '' || value == null) {
    return null;
  }
  const parsed = Number(value);
  if (isNaN(parsed)) {
    return null;
  }
  switch (fieldType) {
    case 'int':
      if (!Number.isInteger(parsed) || String(value).includes('.')) {
        return null;
      }
      break;
    case 'double':
    case 'currency':
      // Treat currency as a double.
      break;
    case 'percent':
      break;
    case 'autonumber':
      break;
    default:
      break;
  }
  return parsed;
}

function formatNumber(value, fieldType, disableGrouping = false) {
  if (value == null || value === '') {
    return '';
  }
  if (fieldType === 'autonumber') {
    return value;
  }
  let fractionDigits = 0;
  if (fieldType === 'double' || fieldType === 'percent' || fieldType === 'currency') {
    fractionDigits = 2;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    useGrouping: !disableGrouping,
  });
  const parsed = Number(value);
  if (isNaN(parsed)) {
    return value;
  }
  return formatter.format(parsed);
}

export default function NumberField({
  fieldName,
  label,
  value,
  isEditing,
  onFieldChange,
  isChanged,
  fieldDefinition = {},
  recordId,
  messageId,
}) {
  const dispatch = useDispatch();
  const isUpdateable = fieldDefinition.updateable ?? false;
  const [copied, setCopied] = useState(false);

  const fieldType = (fieldDefinition.type || '').toLowerCase();
  const compositeKey = `${messageId}_${recordId}`;
  const isFlashing = useSelector(
    (state) => !!state.flashFields[compositeKey]?.[fieldName]
  );
  const validationError = useSelector(
    (state) =>
      state.validation.validationsByCompositeKey[compositeKey]?.issues[fieldName]
  );

  const errorMapping = {
    int: 'integer',
    double: 'decimal',
    percent: 'percentage',
    currency: 'decimal'
  };

  useEffect(() => {
    if (fieldDefinition.required && (!value || value === '')) {
      dispatch(
        setValidationError({
          recordId,
          messageId,
          fieldName,
          errorType: 'Required field',
        })
      );
      return;
    }
    if (fieldType !== 'autonumber') {
      const parsed = parseNumber(value, fieldType);
      if (parsed == null) {
        if (value) {
          dispatch(
            setValidationError({
              recordId,
              messageId,
              fieldName,
              errorType: `Invalid ${errorMapping[fieldType] || fieldType}`,
            })
          );
          return;
        }
      }
    }
    dispatch(clearValidationError({ recordId, messageId, fieldName }));
  }, [value, fieldType, fieldDefinition.required, dispatch, recordId, messageId, fieldName, errorMapping]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(String(value ?? ''));
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const disableGrouping = fieldName.toLowerCase().includes('year') || label.toLowerCase().includes('year');

  if (!isEditing || !isUpdateable || fieldType === 'autonumber') {
    const formattedValue =
      fieldType === 'autonumber'
        ? (value || '')
        : formatNumber(value, fieldType, disableGrouping);
    const labelClass = `mr-1 ${isChanged ? 'text-indigo-500' : ''}`;
    const containerClasses = `flex flex-col items-start ${isFlashing ? 'text-indigo-600' : 'text-gray-600'}`;
    const content = (
      <>
        <strong className={labelClass}>{label}:</strong>
        <span className="truncate overflow-hidden whitespace-nowrap text-ellipsis flex-1">
          {formattedValue}
        </span>
        <button
          onClick={handleCopy}
          aria-label="Copy field value"
          className="inline-flex items-center ml-1 opacity-0 group-hover:opacity-100 transition-opacity flex-none"
        >
          {copied ? (
            <CheckIcon className="h-4 w-4 text-indigo-600" />
          ) : (
            <DocumentDuplicateIcon className="h-3 w-3 text-gray-300 hover:text-indigo-600" />
          )}
        </button>
      </>
    );
    return (
      <div className={containerClasses}>
        {isFlashing ? (
          <motion.div
            className="w-full"
            initial={{ opacity: 0.7 }}
            animate={{ opacity: [0.7, 1, 0.7] }}
            transition={{ duration: 2.0, repeat: Infinity, ease: 'easeInOut' }}
          >
            <div className="flex items-center w-full">{content}</div>
          </motion.div>
        ) : (
          <div className="flex items-center w-full">{content}</div>
        )}
        {isEditing && validationError && (
          <small className="text-red-500 text-xs mt-1">{validationError}</small>
        )}
      </div>
    );
  }

  const labelClassFinal = `mr-1 ${validationError ? 'text-red-500' : isChanged ? 'text-indigo-600' : ''}`;
  const handleChange = (e) => {
    onFieldChange(fieldName, e.target.value);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center text-xs sm:text-xsm md:text-xsm lg:text-xsm text-gray-600 w-full">
        <strong className={labelClassFinal}>{label}:</strong>
        <div className="relative flex-1">
          <input
            type="text"
            value={value ?? ''}
            onChange={handleChange}
            className={`relative w-full rounded border ${isChanged ? 'border-indigo-600' : 'border-gray-300'} px-2 py-1 text-left text-gray-700 focus:outline-none focus:border-indigo-600 bg-white font-sans`}
            placeholder="Enter a number"
          />
        </div>
      </div>
      {isEditing && validationError && (
        <small className="text-red-500 text-xs mt-1">{validationError}</small>
      )}
    </div>
  );
}