import React from 'react';
import { useSelector } from 'react-redux';
import SettingsMenu from './SettingsMenu.jsx';
import SessionUpdateCounter from './SessionUpdateCounter.jsx';
import demoScript from '../../demoScript.js';

const ChatHeader = () => {
  // Use the redux state for demoRunning
  const demoRunning = useSelector(state => state.ui.demoRunning);

  return (
    <header className="bg-[#F3F3F3] w-full rounded-t-xl">
      <div className="flex items-center justify-between p-2 mx-auto max-w-[700px]">
        <img
          src="/assets/Chat_Logo_01.png"
          alt="Tarn Chat Logo"
          className="h-7 ml-2"
        />
        <div className="flex items-center space-x-2">
          <SessionUpdateCounter />
          <SettingsMenu />
        </div>
      </div>
    </header>
  );
};

export default ChatHeader;