import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null, 
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    updateCredits: (state, action) => {
      if (state.user) {
        state.user.creditBalance = { $numberDecimal: action.payload.toString() };
      }
    },
  },
});

export const { setUser, clearUser, updateCredits } = userSlice.actions;
export default userSlice.reducer;