import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import parseContent from './utils/parseContent.js';

const SystemTextCard = ({ content, timestamp, model, credits, shouldAnimate }) => {
  const [displayedText, setDisplayedText] = useState('');
  const typingSpeed = 10;
  const timeoutsRef = useRef([]);

  useEffect(() => {
    return () => {
      timeoutsRef.current.forEach((id) => clearTimeout(id));
      timeoutsRef.current = [];
    };
  }, []);

  useEffect(() => {
    timeoutsRef.current.forEach((id) => clearTimeout(id));
    timeoutsRef.current = [];
    
    if (shouldAnimate) {
      setDisplayedText('');
      
      for (let i = 0; i < content.length; i++) {
        const timeoutId = setTimeout(() => {
          setDisplayedText((prev) => prev + content.charAt(i));
        }, i * typingSpeed);
        timeoutsRef.current.push(timeoutId);
      }
    } else {
      setDisplayedText(content);
    }
  }, [content, shouldAnimate]);

  const timeString = new Date(timestamp)
    .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    .replace(/^0/, '');


  const formattedCredits =
    credits != null
      ? parseFloat(credits.$numberDecimal || credits).toFixed(3)
      : null;

  const handleCopyPlainText = () => {
    navigator.clipboard.writeText(content).then(() =>
      console.log('Entire message copied:', content)
    );
  };

  return (
    <div className="flex items-start justify-start w-full mb-4 group">
      <img
        src="/assets/Icon_Black.png"
        alt="System Icon"
        className="h-6 w-6 mt-3"
      />
      <div className="relative w-full md:w-auto">
        <motion.div
          className="px-3 pt-3 pb-1 bg-gray-100 text-left rounded-lg"
          initial={{ opacity: shouldAnimate ? 0 : 1 }}
          animate={{ opacity: 1 }}
          transition={{ duration: shouldAnimate ? 0.8 : 0 }}
        >
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="text-sm text-gray-800 whitespace-pre-wrap"
          >
            {parseContent(displayedText)}
          </motion.span>
        </motion.div>

        <div className="flex items-center space-x-2 text-xs text-gray-500 mt-1 ml-5 opacity-0 group-hover:opacity-100 transition-opacity">
          {formattedCredits && (
            <span>
              <strong>Credits:</strong> {formattedCredits}
            </span>
          )}
          <span>{timeString}</span>
          <button
            onClick={handleCopyPlainText}
            className="inline-flex items-center justify-center"
          >
            <DocumentDuplicateIcon className="h-4 w-4 hover:text-gray-700" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SystemTextCard;