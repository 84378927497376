import React from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

const parseContent = (text) => {
  const parts = text.split(/(<c>.*?<\/c>|\*\*.*?\*\*|_.*?_)/g);
  return parts.map((part, index) => {
    if (part.startsWith('<c>') && part.endsWith('</c>')) {
      const copyableText = part.slice(3, -4);
      return (
        <span
          key={index}
          className="cursor-pointer text-indigo-600 hover:underline"
          onClick={() => navigator.clipboard.writeText(copyableText)}
        >
          {copyableText}
          <DocumentDuplicateIcon className="h-4 w-4 inline-block ml-1 align-middle" />
        </span>
      );
    }
    if (part.startsWith('**') && part.endsWith('**')) {
      return (
        <strong key={index} className="font-bold">
          {part.slice(2, -2)}
        </strong>
      );
    }
    if (part.startsWith('_') && part.endsWith('_')) {
      return (
        <em key={index} className="italic">
          {part.slice(1, -1)}
        </em>
      );
    }
    return part;
  });
};

export default parseContent;