import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatUserObjects: [], 
};

const chatUserObjectsSlice = createSlice({
  name: 'chatUserObjects',
  initialState,
  reducers: {
    setChatUserObjects(state, action) {
      state.chatUserObjects = action.payload;
    },
    updateLayoutByObjectName(state, action) {
      const { objectName, newLayout } = action.payload;
      const index = state.chatUserObjects.findIndex(obj => obj.name === objectName);
      if (index !== -1) {
        state.chatUserObjects[index].layout = newLayout;
      } else {
        state.chatUserObjects.push({ name: objectName, layout: newLayout });
      }
    },
  },
});

export const { setChatUserObjects, updateLayoutByObjectName } = chatUserObjectsSlice.actions;

export default chatUserObjectsSlice.reducer;