import React from 'react';
import HeroIcon from '../utils/HeroIcon.jsx';
import { getHeroIconName } from '../utils/recordCardUtils.js';
import RecordCardFields from './RecordCardFields.jsx';

const RelatedRecordCard = ({ relatedRecords, chatObjects, messageId }) => {
  if (!relatedRecords || relatedRecords.length === 0) return null;

  return (
    <div className="bg-gray-100 border-t border-gray-300 p-4">
      <div className="text-sm font-semibold text-gray-800 mb-2">Related Records:</div>
      {relatedRecords.map((record, index) => {
        const heroIconName = getHeroIconName(chatObjects, record.attributes?.type);
        const relatedDisplayName =
          record.Name ||
          (record.FirstName && record.LastName
            ? `${record.FirstName} ${record.LastName}`
            : null) ||
          record.Id;

        return (
          <div
            key={index}
            className="mb-4 p-3 bg-white rounded-lg shadow-sm border border-gray-200"
          >
            <div className="flex items-center space-x-2 text-sm text-gray-600 mb-2">
              {heroIconName && (
                <HeroIcon
                  name={heroIconName}
                  size={6}
                  className="text-gray-600"
                />
              )}
              <strong className="mt-1">{relatedDisplayName}</strong>
            </div>
            <RecordCardFields record={record} messageId={messageId} />
          </div>
        );
      })}
    </div>
  );
};

export default RelatedRecordCard;