import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { removeDraftField } from '../../../../../redux/slices/draftRecordSlice.js';
import { XCircleIcon } from '@heroicons/react/24/outline';

export default function UpdatePreview({ record, draftRecord, messageId }) {
  const dispatch = useDispatch();
  const previewSending = useSelector((state) => state.ui.previewSending);
  const chatObjects = useSelector((state) => state.chatObjects.chatObjects);

  if (!record || !draftRecord) return null;

  const matchingChatObject = chatObjects.find(
    (obj) => obj.name === record.attributes.type
  );

  const updatedFields = [];
  for (const [fieldName, fieldData] of Object.entries(draftRecord)) {
    if (fieldName.endsWith('__display')) continue;

    let newVal, displayValue;
    if (typeof fieldData === 'object' && fieldData !== null && 'value' in fieldData) {
      newVal = fieldData.value;
      displayValue = fieldData.displayName;
    } else {
      newVal = fieldData;
      displayValue = fieldData;
    }
    const oldVal = record[fieldName] ?? null;
    if (newVal !== oldVal) {
      let fieldLabel = fieldName;
      if (matchingChatObject && matchingChatObject.fields) {
        const fieldObj = matchingChatObject.fields.find(
          (field) => field.name === fieldName
        );
        if (fieldObj && fieldObj.label) {
          fieldLabel = fieldObj.label;
        }
      }
      updatedFields.push({
        field: fieldLabel,
        oldValue: oldVal !== null ? oldVal : '[empty]',
        newValue: displayValue !== null ? displayValue : '[null]',
      });
    }
  }

  if (!updatedFields.length) return null;

  const handleRemoveField = (fieldName) => {
    dispatch(removeDraftField({ recordId: record.Id, messageId, fieldName }));
  };

  const containerVariants = {
    initial: { opacity: 0, y: -50, scale: 0.95, rotate: 2 },
    animate: {
      opacity: 1,
      y: 0,
      scale: 1,
      rotate: 0,
      transition: { duration: 2.0, ease: [0.22, 1, 0.36, 1], staggerChildren: 0.1 },
    },
    exit: {
      opacity: 0,
      y: -50,
      scale: 0.95,
      rotate: 2,
      transition: { duration: 2.0, ease: [0.22, 1, 0.36, 1], staggerChildren: 0.1 },
    },
  };

  const fieldVariants = {
    initial: { opacity: 0, y: -20 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 2.0, ease: [0.22, 1, 0.36, 1] },
    },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <AnimatePresence>
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate={previewSending ? 'exit' : 'animate'}
        exit="exit"
        transition={{
          type: 'spring',
          damping: 20,
          stiffness: 100,
          duration: 5.0,
          ease: [0.22, 1, 0.36, 1],
        }}
        className="flex flex-col w-full rounded-lg bg-[#F2F0F8] pt-3 px-3 pb-1 mt-3 mb-1"
      >
        {updatedFields.map(({ field, oldValue, newValue }) => (
          <motion.div key={field} variants={fieldVariants}>
            <div className="flex items-center w-full pb-3">
              <span className="w-1/5 font-medium text-indigo-600 truncate">{field}</span>
              <span className="w-1/12 text-gray-700 text-center flex justify-center">from</span>
              <span className="w-1/4 flex justify-center min-w-0">
                <span
                  className="overflow-hidden rounded-md italic text-gray-400 text-ellipsis whitespace-nowrap text-center"
                  title={oldValue}
                >
                  {String(oldValue)}
                </span>
              </span>
              <span className="w-1/12 text-gray-700 text-center flex justify-center">to</span>
              <span className="w-1/3 flex justify-center min-w-0">
                <span
                  className="overflow-hidden font-medium text-ellipsis whitespace-nowrap text-center"
                  title={newValue}
                >
                  {String(newValue)}
                </span>
              </span>
              <span className="ml-auto flex justify-center items-center">
                <XCircleIcon
                  className="h-5 w-5 text-red-300 cursor-pointer hover:text-red-500 transition-colors"
                  title="Remove"
                  onClick={() => handleRemoveField(field)}
                />
              </span>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </AnimatePresence>
  );
}