import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  text: '',
};

const userMessageTyperSlice = createSlice({
  name: 'userMessageTyper',
  initialState,
  reducers: {
    setUserMessageTyperText: (state, action) => {
      state.text = action.payload;
    },
    clearUserMessageTyperText: (state) => {
      state.text = '';
    },
  },
});

export const { setUserMessageTyperText, clearUserMessageTyperText } = userMessageTyperSlice.actions;
export default userMessageTyperSlice.reducer;