import React, { useEffect, useState, Fragment } from 'react';
import { motion, useMotionValue, animate } from 'framer-motion';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';

const SessionUpdateCounter = () => {
  const updates = useSelector((state) => state.chat.chatSession.updates) || [];

  const groupedRecords = updates.reduce((acc, update) => {
    const { recordId, displayName, fieldUpdates = [] } = update;
    if (!acc[recordId]) {
      acc[recordId] = {
        recordId,
        displayName: displayName || recordId,
        fieldUpdates: [],
      };
    }
    acc[recordId].fieldUpdates = acc[recordId].fieldUpdates.concat(fieldUpdates);
    return acc;
  }, {});

  const recordGroups = Object.values(groupedRecords);

  const totalFieldUpdatesCount = recordGroups.reduce(
    (sum, record) => sum + record.fieldUpdates.length,
    0
  );

  const motionValue = useMotionValue(totalFieldUpdatesCount);
  const [displayCount, setDisplayCount] = useState(totalFieldUpdatesCount);

  useEffect(() => {
    const controls = animate(motionValue, totalFieldUpdatesCount, {
      duration: 0.5,
      onUpdate: (latest) => {
        setDisplayCount(Math.floor(latest));
      },
    });
    return () => controls.stop();
  }, [totalFieldUpdatesCount, motionValue]);

  // Removed socket-based conditional classes; using a single default style instead.
  const classes =
    "ml-2 inline-flex h-8 w-8 items-center justify-center rounded-full text-sm font-bold bg-[#F4BBFF] text-indigo-500 cursor-pointer";

  // Format a timestamp to a time string (e.g., "9:35am")
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date
      .toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
      .toLowerCase();
  };

  // Track which records are expanded
  const [expandedRecords, setExpandedRecords] = useState({});

  const toggleRecord = (recordId) => {
    setExpandedRecords((prev) => ({
      ...prev,
      [recordId]: !prev[recordId],
    }));
  };

  return (
    <Popover className="relative">
      <PopoverButton
        as={motion.div}
        key={displayCount}
        initial={{ scale: 1.4 }}
        animate={{ scale: 1 }}
        whileHover={{ scale: 1.2 }}
        transition={{ type: 'spring', stiffness: 500, damping: 20 }}
        className={classes}
      >
        {displayCount}
      </PopoverButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="absolute right-6 z-[9999] mt-1 w-72 max-h-96 overflow-y-auto rounded-md bg-white p-2 py-3 shadow-lg border border-gray-300 text-xs space-y-3">
          {recordGroups.map((record) => {
            const { recordId, displayName, fieldUpdates } = record;
            const count = fieldUpdates.length;
            // Compute the timestamp of the last field update (if any)
            const lastTimestamp =
              count > 0
                ? Math.max(...fieldUpdates.map((f) => new Date(f.timestamp).getTime()))
                : null;
            const lastTimeFormatted = lastTimestamp ? formatTime(lastTimestamp) : '';

            return (
              <div key={recordId} className="border-b border-gray-200 pb-2">
                {/* Record summary row */}
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => toggleRecord(recordId)}
                >
                  <div className="flex items-center space-x-2">
                    {/* Truncated display name */}
                    <span
                      className="font-semibold text-gray-700 truncate"
                      style={{ maxWidth: '150px' }}
                    >
                      {displayName}
                    </span>
                    <span className="text-indigo-600 text-xs">
                      ({count} update{count !== 1 ? 's' : ''})
                    </span>
                  </div>
                  <span className="whitespace-nowrap text-gray-400 text-xs">
                    {lastTimeFormatted}
                  </span>
                </div>

                {/* Expanded record details */}
                {expandedRecords[recordId] && (
                  <div className="mt-3 space-y-2 pl-4">
                    {fieldUpdates.map((update, idx) => (
                      <div key={idx} className="flex items-center justify-between">
                        <div className="flex items-center space-x-1 truncate">
                          <span className="font-semibold text-gray-700 rounded-md border border-gray-300 px-2">
                            {update.fieldName}
                          </span>
                          <span className="text-gray-700 px-1">to</span>
                          <span className="truncate text-indigo-600">
                            {update.newValue}
                          </span>
                        </div>
                        <span className="whitespace-nowrap text-gray-400 text-xs ml-1">
                          {formatTime(update.timestamp)}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default SessionUpdateCounter;