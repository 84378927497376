import React, { useEffect, useState, Fragment } from 'react';
import { motion, useMotionValue, animate } from 'framer-motion';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';

const RecordUpdateCounter = ({ count, updates = [], objectType, onOpenChange }) => {
  const motionValue = useMotionValue(count);
  const [displayCount, setDisplayCount] = useState(count);

  useEffect(() => {
    const controls = animate(motionValue, count, {
      duration: 0.5, // Adjust duration as desired
      onUpdate: (latest) => setDisplayCount(Math.floor(latest)),
    });
    return () => controls.stop();
  }, [count, motionValue]);

  const chatObjects = useSelector((state) => state.chatObjects.chatObjects);
  // Find the chat object that matches the provided objectType.
  const currentObject = (chatObjects || []).find((obj) => obj.name === objectType);

  // Format timestamp as a time string (e.g., 9:35am).
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }).toLowerCase();
  };

  return (
    <Popover className="relative">
      {({ open }) => {
        // Directly notify the parent of the current open state.
        if (onOpenChange) {
          onOpenChange(open);
        }
        return (
          <>
            <PopoverButton
              as={motion.div}
              key={displayCount}
              initial={{ scale: open ? 1 : 1.4 }}
              animate={{ scale: 1 }}
              whileHover={open ? {} : { scale: 1.2 }}
              transition={{ type: 'spring', stiffness: 500, damping: 20 }}
              className="ml-2 inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-[#F4BBFF] text-sm font-bold text-indigo-500 focus:outline-none"
            >
              {displayCount}
            </PopoverButton>

            <Transition
              as={Fragment}
              onEnter={() => {}}
              onExited={() => {}}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute right-6 z-[9999] mt-1 w-72 max-h-64 overflow-y-auto rounded-md bg-white p-2 py-3 shadow-lg border border-gray-300 text-xs space-y-3">
                {updates.map((update, index) => {
                  // Look up the field label in the found object's fields array.
                  const field = (currentObject?.fields || []).find(
                    (f) => f.name === update.fieldName
                  );
                  const fieldLabel = field ? field.label : update.fieldName;
                  return (
                    <div key={index} className="flex items-center justify-between">
                      <div className="flex items-center space-x-1 truncate">
                        <span className="font-semibold text-gray-700 rounded-md border border-gray-300 px-2">
                          {fieldLabel}
                        </span>
                        <span className="text-gray-700 px-1">to</span>
                        <span className="truncate text-indigo-600">{update.newValue}</span>
                      </div>
                      <span className="whitespace-nowrap text-gray-400 text-xs ml-1">
                        {formatTime(update.timestamp)}
                      </span>
                    </div>
                  );
                })}
              </PopoverPanel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};

export default RecordUpdateCounter;