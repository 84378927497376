import React from 'react';
import * as HeroIcons from '@heroicons/react/24/solid'; // Import all Heroicons

const HeroIcon = ({ name, size = 24, className = '' }) => {
    const IconComponent = HeroIcons[name];

    if (!IconComponent) {
        console.error(`HeroIcon: No icon found with name "${name}"`);
        return null;
    }

    return <IconComponent className={`h-${size} w-${size} ${className}`} />;
};

export default HeroIcon;