const navigation = {
  main: [
    { name: 'Product', href: '/product' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Team', href: '/team' },
    { name: 'Release Notes', href: '/release-notes' },
    { name: 'Data Privacy Policy', href: '/data-policy' },
    { name: 'Terms & Conditions', href: '/terms-and-conditions' },
  ],
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/tarn-apps-inc/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.2.8 24 1.77 24h20.45C23.2 24 24 23.2 24 22.28V1.72C24 .8 23.2 0 22.23 0zM7.12 20.45H3.56V9H7.1v11.45zm-1.78-12.8c-1.17 0-2.12-.97-2.12-2.15S4.2 3.3 5.35 3.3c1.18 0 2.13.96 2.13 2.14 0 1.18-.96 2.12-2.13 2.12zM20.45 20.45h-3.56v-5.6c0-1.33-.03-3.05-1.86-3.05-1.86 0-2.15 1.45-2.15 2.95v5.7h-3.56V9h3.42v1.57h.05c.47-.88 1.62-1.8 3.33-1.8 3.56 0 4.22 2.34 4.22 5.38v6.3z" />
        </svg>
      ),
    },
    {
      name: 'YouTube',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return (
    <footer className="relative z-10">
      <div className="mx-auto max-w-7xl px-6 py-20 mt-12 sm:py-24 lg:px-8">
        <div className="flex items-center justify-center space-x-3">
          <img
            src="/assets/Logo_Black.png"
            alt="Tarn Black Logo"
            className="h-9"
          />
          <span className="text-gray-700 text-xl mt-2">
            has entered the chat.
          </span>
        </div>

        <nav
          aria-label="Footer"
          className="mt-12 flex flex-wrap justify-center gap-x-12 gap-y-3 text-sm/6"
        >
          {navigation.main.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="block text-gray-600 hover:text-gray-900"
            >
              {item.name}
            </a>
          ))}
        </nav>

        <div className="mt-16 flex justify-center gap-x-10">
          {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-600 hover:text-gray-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon aria-hidden="true" className="h-6 w-6" />
            </a>
          ))}
        </div>

        <p className="mt-10 text-center text-sm/6 text-gray-600">
          &copy; 2024 Tarn Apps Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}