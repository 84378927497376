import React, { useState, Fragment, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Transition,
} from '@headlessui/react';
import {
  DocumentDuplicateIcon,
  CheckIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import {
  clearValidationError,
  setValidationError,
} from '../../../../../../redux/slices/validationSlice.js';

const Portal = ({ children }) => createPortal(children, document.body);

export default function PicklistField({
  fieldName,
  label,
  value,
  isEditing,
  onFieldChange,
  isChanged,
  fieldDefinition,
  recordId,
  messageId,
}) {
  const dispatch = useDispatch();
  const isUpdateable = fieldDefinition?.updateable ?? false;
  const [copied, setCopied] = useState(false);
  const buttonRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(String(value));
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const allValidationErrors = useSelector((state) => state.validation);

  const compositeKey = `${messageId}_${recordId}`;
  const validationError = useSelector(
    (state) =>
      state.validation.validationsByCompositeKey[compositeKey]?.issues[fieldName]
  );
  const isFlashing = useSelector(
    (state) => !!state.flashFields[compositeKey]?.[fieldName]
  );

  const options = Array.isArray(fieldDefinition.picklistValues)
    ? fieldDefinition.picklistValues
    : [];


  useEffect(() => {
    if (options.length > 0) {
      const validValues = options.map((opt) => opt.value);
      if (fieldDefinition?.required && (!value || value === '')) {
        dispatch(
          setValidationError({
            recordId,
            messageId,
            fieldName,
            errorType: 'Required field',
          })
        );
      }
      else if (value && !validValues.includes(value)) {
        dispatch(
          setValidationError({
            recordId,
            messageId,
            fieldName,
            errorType: 'Invalid picklist value.',
          })
        );
      } else {
        dispatch(clearValidationError({ recordId, messageId, fieldName }));
      }
    }
  }, [value, options, dispatch, recordId, messageId, fieldName, fieldDefinition]);

  if (!isEditing || !isUpdateable) {
    const labelClass = `mr-1 ${validationError ? 'text-red-500' : isChanged ? 'text-indigo-500' : ''
      }`;

    const content = (
      <>
        <strong className={labelClass}>{label}:</strong>
        <span className="truncate overflow-hidden whitespace-nowrap text-ellipsis flex-1">
          {value || ''}
        </span>
        {!isEditing && (
          <button
            onClick={handleCopy}
            aria-label="Copy field value"
            className="inline-flex items-center ml-1 opacity-0 group-hover:opacity-100 transition-opacity flex-none"
          >
            {copied ? (
              <CheckIcon className="h-4 w-4 text-indigo-600" />
            ) : (
              <DocumentDuplicateIcon className="h-3 w-3 text-gray-300 hover:text-indigo-600" />
            )}
          </button>
        )}
      </>
    );

    return (
      <div className="flex flex-col items-start">
        {isFlashing ? (
          <motion.div
            className="w-full flex items-center text-indigo-600"
            initial={{ opacity: 0.7 }}
            animate={{ opacity: [0.7, 1, 0.7] }}
            transition={{ duration: 2.0, repeat: Infinity, ease: 'easeInOut' }}
          >
            {content}
          </motion.div>
        ) : (
          <div className="flex items-center text-gray-600 w-full">{content}</div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center text-xs sm:text-xsm md:text-xsm lg:text-xsm text-gray-600 w-full">
        <strong
          className={`mr-1 ${validationError ? 'text-red-500' : isChanged ? 'text-indigo-600' : ''
            }`}
        >
          {label}:
        </strong>
        <div className="relative flex-1">
          <Listbox
            value={value}
            onChange={(val) => {
              onFieldChange(fieldName, val);
              const validValues = options.map((opt) => opt.value);
              if (val === '' || validValues.includes(val)) {
                dispatch(clearValidationError({ recordId, messageId, fieldName }));
              }
            }}
          >
            {({ open }) => {
              if (open && buttonRef.current) {
                const rect = buttonRef.current.getBoundingClientRect();
                if (
                  rect.bottom !== position.top ||
                  rect.left !== position.left ||
                  rect.width !== position.width
                ) {
                  setTimeout(() => {
                    setPosition({
                      top: rect.bottom,
                      left: rect.left,
                      width: rect.width,
                    });
                  }, 0);
                }
              }
              return (
                <>
                  <ListboxButton
                    ref={buttonRef}
                    className={`relative w-full max-w-full cursor-pointer rounded border ${isChanged ? 'border-indigo-600' : 'border-gray-300'
                      } px-2 py-1 text-left text-gray-700 focus:outline-none focus:border-indigo-600 ${open ? 'bg-[#F2F0F8]/50' : 'bg-[#F2F0F8]'
                      }`}
                  >
                    <span className="block truncate">{value || '-- None --'}</span>
                    <ChevronDownIcon className="pointer-events-none absolute top-1/2 right-2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                  </ListboxButton>

                  {open && (
                    <Portal>
                      <Transition
                        as={Fragment}
                        show={open}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <ListboxOptions
                          className="mt-1 max-h-60 overflow-auto rounded bg-[rgba(242,240,248,0.95)] py-1 text-xs sm:text-xsm md:text-xsm lg:text-xsm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          style={{
                            position: 'fixed',
                            top: position.top,
                            left: position.left,
                            width: position.width,
                            zIndex: 9999,
                          }}
                        >
                          <ListboxOption value="">
                            {({ active, selected }) => (
                              <div
                                className={`cursor-pointer select-none px-4 py-2 
                                  ${active ? 'bg-indigo-200' : ''}
                                  ${selected
                                    ? 'bg-indigo-500 text-white'
                                    : 'text-gray-900'
                                  }`}
                              >
                              </div>
                            )}
                          </ListboxOption>

                          {options.map((picklistObj) => (
                            <ListboxOption
                              key={picklistObj.value}
                              value={picklistObj.value}
                            >
                              {({ active, selected }) => (
                                <div
                                  className={`cursor-pointer select-none px-4 py-2 
                                    ${active ? 'bg-indigo-200' : ''}
                                    ${selected
                                      ? 'bg-indigo-500 text-white'
                                      : 'text-gray-900'
                                    }`}
                                >
                                  {picklistObj.label}
                                </div>
                              )}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Transition>
                    </Portal>
                  )}
                </>
              );
            }}
          </Listbox>
        </div>
      </div>
      {validationError && (
        <small className="text-red-500 text-xs mt-1">{validationError}</small>
      )}
    </div>
  );
}