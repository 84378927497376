// sessionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatSession: {
    _id: null,
    userId: null,
    orgId: null,
    messages: [],
    updates: [],
    recents: [],
    createdAt: null,
    updatedAt: null,
  },
};

const sessionSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatSession: (state, action) => {
      state.chatSession = action.payload;
    },
    addUserMessage: (state, action) => {
      const newMessage = action.payload;
      state.chatSession.messages.push(newMessage);
      state.chatSession.updatedAt = new Date().toISOString();
    },
    addSystemMessage: (state, action) => {
      const newMessage = action.payload;
      if (!newMessage.sender) {
        newMessage.sender = 'system';
      }
      console.log('adding system message', newMessage);
      state.chatSession.messages.push(newMessage);
      state.chatSession.updatedAt = new Date().toISOString();
    },
    updateMessageById: (state, action) => {
      const { messageId, updates } = action.payload;
      const message = state.chatSession.messages.find((msg) => msg.messageId === messageId);
      if (message) {
        Object.assign(message, updates);
        state.chatSession.updatedAt = new Date().toISOString();
      }
    },
    addToFieldUpdates: (state, action) => {
      const newUpdate = action.payload;
      state.chatSession.updates.push(newUpdate);
    },
    deleteMessageById: (state, action) => {
      const { messageId } = action.payload;
      state.chatSession.messages = state.chatSession.messages.filter(
        (msg) => msg.messageId !== messageId
      );
      state.chatSession.updatedAt = new Date().toISOString();
    },
    // New reducer to replace the recents on the session without changing anything else.
    replaceRecents: (state, action) => {
      state.chatSession.recents = action.payload;
      state.chatSession.updatedAt = new Date().toISOString();
    },
  },
});

export const {
  setChatSession,
  addUserMessage,
  addSystemMessage,
  updateMessageById,
  addToFieldUpdates,
  deleteMessageById,
  replaceRecents, // export the new action
} = sessionSlice.actions;

export default sessionSlice.reducer;