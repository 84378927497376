import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline';
import HeroIcon from '../utils/HeroIcon.jsx';
import { motion } from 'framer-motion';
import {
  formatTimeString,
  formatCredits,
  getHeroIconName
} from '../utils/recordCardUtils.js';
import RecordCardFields from './RecordCardFields.jsx';
import RelatedRecordCard from './RelatedRecordCard.jsx';
import RecordUpdateCounter from './RecordUpdateCounter.jsx';

const RecordCard = ({ content, timestamp, model, credits, messageId }) => {
  const { attributes, relatedRecords } = content;
  const chatObjects = useSelector((state) => state.chatObjects.chatObjects);
  const salesforceInstance = useSelector(
    (state) => state.organization.organization?.salesforceInstance
  );
  const updates = useSelector((state) => state.chat.chatSession.updates);
  const matchingUpdates =
    updates?.filter((u) => u.recordId === content.Id && u.messageId === messageId) || [];
  const allFieldUpdates = matchingUpdates.flatMap((updateObj) => updateObj.fieldUpdates || []);
  const totalFieldUpdatesCount = matchingUpdates.reduce(
    (sum, update) => sum + (update.fieldUpdates?.length || 0),
    0
  );
  const objectType = attributes?.type;
  const editingRecords = useSelector((state) => state.isEditing.editingRecords);
  const compositeKey = `${messageId}_${content.Id}`;
  const isEditing = editingRecords[compositeKey]?.isEditing || false;
  const timeString = formatTimeString(timestamp);
  const formattedCredits =
    credits != null
      ? parseFloat(credits.$numberDecimal || credits).toFixed(3)
      : null;
  const headerHeroIconName = getHeroIconName(chatObjects, attributes?.type);
  const numberOfRelatedRecords = relatedRecords?.length || 0;

  const [showRelatedRecords, setShowRelatedRecords] = useState(false);
  const [copied, setCopied] = useState(false);
  // Track whether the RecordUpdateCounter's popover is open.
  const [updateCounterOpen, setUpdateCounterOpen] = useState(false);

  const displayName =
    (content.attributes && content.attributes.type === 'Task' ? content.Subject : null) ||
    content.Name ||
    (content.FirstName && content.LastName ? `${content.FirstName} ${content.LastName}` : null) ||
    content.Id;

  const salesforceLink = salesforceInstance ? `${salesforceInstance}/${content.Id}` : '';
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(salesforceLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } catch (err) {
      console.error('Failed to copy link:', err);
    }
  };

  // Freeze animation when either isEditing is true OR the update counter popover is open.
  const freezeAnimation = isEditing || updateCounterOpen;

  return (
    <div className="w-full group">
      <motion.div
        initial={{ boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08)', scale: 1 }}
        animate={
          freezeAnimation
            ? { boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08)', scale: 1 }
            : {}
        }
        whileHover={
          freezeAnimation
            ? {}
            : { boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)', scale: 1.01 }
        }
        transition={{ type: 'spring', stiffness: 150, damping: 20 }}
        className="relative max-w-screen-lg mx-auto rounded-lg bg-gray-100 border border-gray-300"
      >
        <div className="bg-[#F2F0F8] text-gray-800 px-3 py-2 rounded-t-lg flex items-center justify-between">
          <div className="flex items-center space-x-2 flex-1 min-w-0 font-semibold">
            {headerHeroIconName && (
              <HeroIcon
                name={headerHeroIconName}
                size={6}
                className="text-indigo-500 flex-shrink-0 h-6 w-6"
              />
            )}
            <span className="truncate pr-1">
              {editingRecords[compositeKey]?.type === 'create'
                ? `Creating ${editingRecords[compositeKey].recordType}`
                : displayName}
            </span>
          </div>
          {/* Right side: fixed elements */}
          <div className="flex items-center space-x-2">
            {salesforceInstance && (
              <>
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="iphone:hidden text-xs text-indigo-600 hover:text-gray-700 transition-opacity opacity-0 group-hover:opacity-100 cursor-pointer"
                >
                  view in Salesforce
                </a>
                <button
                  onClick={handleCopyLink}
                  className="cursor-pointer transition-opacity opacity-0 group-hover:opacity-100"
                >
                  {copied ? (
                    <CheckIcon className="h-4 w-4 text-green-500" />
                  ) : (
                    <DocumentDuplicateIcon className="h-4 w-4 text-indigo-600 hover:text-gray-700" />
                  )}
                </button>
              </>
            )}
            <button
              onClick={() => {
                if (salesforceLink) {
                  window.open(salesforceLink, "_blank");
                }
              }}
              className="cursor-pointer"
            >
              <img
                src="/assets/Salesforce_Corporate_Logo_RGB.png"
                alt="Salesforce Logo"
                className="h-5 w-auto"
              />
            </button>
            {totalFieldUpdatesCount > 0 && (
              <RecordUpdateCounter
                count={totalFieldUpdatesCount}
                updates={allFieldUpdates}
                objectType={objectType}
                onOpenChange={setUpdateCounterOpen}
              />
            )}
          </div>
        </div>

        <div className="px-4 iphone:px-2 pt-4 pb-2 bg-[#FCFBFE] rounded-lg">
          <RecordCardFields
            record={content}
            relatedRecords={relatedRecords}
            showRelatedRecords={showRelatedRecords}
            setShowRelatedRecords={setShowRelatedRecords}
            messageId={messageId}
          />
        </div>

        {numberOfRelatedRecords > 0 && showRelatedRecords && (
          <RelatedRecordCard
            relatedRecords={relatedRecords}
            chatObjects={chatObjects}
            messageId={messageId}
          />
        )}
      </motion.div>

      <div className="flex items-center space-x-2 text-xs text-gray-500 mt-2 ml-5 opacity-0 group-hover:opacity-100 transition-opacity">
        {numberOfRelatedRecords > 0 && (
          <button
            className="text-indigo-600 hover:underline"
            onClick={() => setShowRelatedRecords(!showRelatedRecords)}
          >
            {showRelatedRecords
              ? 'Hide related records'
              : `${numberOfRelatedRecords} related records`}
          </button>
        )}
        {formattedCredits !== null && (
          <span>
            <strong>Credits:</strong> {formattedCredits}
          </span>
        )}
        <span>{timeString}</span>
      </div>
    </div>
  );
};

export default RecordCard;