import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import FieldRenderer from './FieldRenderer.jsx';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { ArrowUpCircleIcon } from '@heroicons/react/24/solid';
import { startEditing, stopEditing } from '../../../../../redux/slices/isEditingSlice.js';
import {
  initializeDraft,
  updateDraftField,
  clearDraft
} from '../../../../../redux/slices/draftRecordSlice.js';
import {
  deleteMessageById,
  addToFieldUpdates
} from '../../../../../redux/slices/sessionSlice.js';
import UpdatePreview from './UpdatePreview.jsx';
import { clearAllValidationErrors } from '../../../../../redux/slices/validationSlice.js';

const EMPTY_OBJ = Object.freeze({});

const selectDraftsByCompositeKey = (state) => state.draftRecord.draftsByCompositeKey;
const makeSelectDraftForCompositeKey = (compositeKey) =>
  createSelector([selectDraftsByCompositeKey], (drafts) => drafts[compositeKey] ?? EMPTY_OBJ);

export default function RecordCardFields({
  record,
  relatedRecords = [],
  showRelatedRecords,
  setShowRelatedRecords,
  messageId
}) {
  const dispatch = useDispatch();
  // Socket code removed for front-end-only version
  const [sending, setSending] = useState(false);
  const chatObjects = useSelector((state) => state.chatObjects.chatObjects);
  const chatUserObjects = useSelector((state) => state.chatUserObjects.chatUserObjects);
  const onboarding = useSelector((state) => state.ui.onboarding);

  const isNoRecord = !record || !record.attributes?.type;
  const compositeKey = `${messageId}_${record?.Id}`;

  const editingRecords = useSelector((state) => state.isEditing.editingRecords);
  const isThisRecordBeingEdited = editingRecords[compositeKey]?.isEditing || false;

  const draftSelector = useMemo(() => makeSelectDraftForCompositeKey(compositeKey), [compositeKey]);
  const draftRecord = useSelector(draftSelector);

  const validationState = useSelector(
    (state) => state.validation.validationsByCompositeKey[compositeKey]
  );
  const hasValidationErrors = !!validationState;

  const formatLastModified = (dateString) => {
    const lastModified = new Date(dateString);
    const now = new Date();
    const diffInMs = now - lastModified;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    let dateLabel = '';
    if (diffInDays === 0) {
      dateLabel = 'Today';
    } else if (diffInDays === 1) {
      dateLabel = 'Yesterday';
    } else {
      dateLabel = `${diffInDays} days ago`;
    }
    const time = lastModified.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
      <>
        {dateLabel} at <span className="font-semibold">{time}</span>
      </>
    );
  };

  useEffect(() => {
    if (isNoRecord) return;
    if (isThisRecordBeingEdited) {
      const initialValues = {};
      Object.entries(record).forEach(([key, val]) => {
        if (key !== 'attributes' && key !== 'Id') {
          initialValues[key] = val ?? null;
        }
      });
      dispatch(initializeDraft({ recordId: record.Id, messageId, initialValues }));
    } else {
      dispatch(clearDraft({ recordId: record.Id, messageId }));
    }
  }, [isNoRecord, isThisRecordBeingEdited, record, dispatch, messageId]);

  const handleFieldChange = (fieldName, newValue, newDisplayValue) => {
    if (!record?.Id) return;
    dispatch(updateDraftField({ recordId: record.Id, messageId, fieldName, newValue, displayName: newDisplayValue }));
  };

  const handleEditClick = () => {
    if (!record?.attributes) return;
    dispatch(startEditing({ recordId: record.Id, recordType: record.attributes.type, messageId }));
  };

  const handleCancelClick = () => {
    if (!record?.Id) return;
    const editingRecordEntry = editingRecords[compositeKey];
    if (editingRecordEntry && editingRecordEntry.type === 'create') {
      dispatch(deleteMessageById({ messageId }));
    }
    dispatch(clearDraft({ recordId: record.Id, messageId }));
    dispatch(stopEditing({ recordId: record.Id, messageId }));
    dispatch(clearAllValidationErrors({ recordId: record.Id, messageId }));
  };

  const handleSaveClick = () => {
    if (!record?.Id) return;
    const updatedFields = [];
    // When iterating draftRecord, check for our object structure.
    for (const [fieldName, fieldData] of Object.entries(draftRecord)) {
      let newVal;
      if (fieldData && typeof fieldData === 'object' && fieldData !== null && 'value' in fieldData) {
        newVal = fieldData.value;
      } else {
        newVal = fieldData;
      }
      const originalVal = record[fieldName] ?? null;
      if (newVal !== originalVal) {
        updatedFields.push({ field: fieldName, oldValue: originalVal, newValue: newVal });
      }
    }
    const editingRecordEntry = editingRecords[compositeKey];
    const payloadType = editingRecordEntry ? editingRecordEntry.type : 'update';
    const submitFieldUpdates = {
      recordId: record.Id,
      recordType: record.attributes.type,
      updates: updatedFields,
      record,
      messageId,
      type: payloadType,
    };
    // Removed socket.emit('submitUpdateCreate', submitFieldUpdates);
    setSending(true);
    setTimeout(() => {
      dispatch(clearDraft({ recordId: record.Id, messageId }));
      dispatch(stopEditing({ recordId: record.Id, messageId }));
      dispatch(clearAllValidationErrors({ recordId: record.Id, messageId }));
      setSending(false);
    }, 500);
  };

  if (isNoRecord) {
    return <div className="text-sm text-gray-500">No record data</div>;
  }

  const objectType = record.attributes.type;
  const matchingChatObject = chatObjects.find((obj) => obj.name === objectType);
  const matchingChatUserObject = chatUserObjects.find((obj) => obj.name === objectType);
  const { fields = [] } = matchingChatObject;
  const { layout = [] } = matchingChatUserObject;
  const sortedLayout = [...layout].sort((a, b) => a.position - b.position);
  const allFieldKeys = Object.keys(record).filter((k) => k !== 'attributes' && k !== 'Id');
  const layoutFieldNames = sortedLayout.map((item) => item.name);

  const layoutFields = sortedLayout
    .filter(layoutItem => Object.prototype.hasOwnProperty.call(record, layoutItem.name))
    .map(layoutItem => ({
      name: layoutItem.name,
      label: layoutItem.label,
      value: record[layoutItem.name] ?? null
    }));

  const leftoverKeys = allFieldKeys.filter((key) => !layoutFieldNames.includes(key));
  const leftoverFields = leftoverKeys.map((key) => {
    const matchingFieldDef = fields.find((f) => f.name === key);
    const label = matchingFieldDef?.label || key;
    return { name: key, label, value: record[key] ?? null };
  });

  const allDisplayFields = [...layoutFields, ...leftoverFields].filter((field) =>
    fields.some((metaField) => metaField.name === field.name) && field.name !== 'LastModifiedDate'
  );

  return (
    <div className="relative group text-xs sm:text-xsm md:text-xsm lg:text-xsm">
      <div className="grid grid-cols-2 gap-y-2 gap-x-4 iphone:gap-x-2 mb-3">
        {allDisplayFields.map((field) => {
          const draftVal = draftRecord[field.name];
          const safeDraftVal =
            draftVal && typeof draftVal === 'object'
              ? draftVal
              : { value: field.value, displayName: field.value || '' };
          const rawCurrentValue = safeDraftVal.value;
          const displayValue = rawCurrentValue == null ? '' : safeDraftVal.displayName;
          const fieldDefinition = fields.find((fDef) => fDef.name === field.name);
          const rawOriginal = record[field.name] ?? null;
          const changed = safeDraftVal.value !== rawOriginal;
          const colSpan = field.name === "Description" ? "col-span-2" : "";

          return (
            <div key={field.name} className={colSpan}>
              <FieldRenderer
                fieldName={field.name}
                label={field.label}
                value={displayValue}
                rawValue={rawCurrentValue}
                fieldDefinition={fieldDefinition}
                relatedRecords={relatedRecords}
                showRelatedRecords={showRelatedRecords}
                setShowRelatedRecords={setShowRelatedRecords}
                isEditing={isThisRecordBeingEdited}
                onFieldChange={(fname, val, newDisplayValue) =>
                  handleFieldChange(fname, val, newDisplayValue)
                }
                isChanged={changed}
                recordId={record.Id}
                messageId={messageId}
              />
            </div>
          );
        })}
      </div>

      <div className="flex justify-between items-center mt-2 w-full">
        {record.LastModifiedDate ? (
          <span className="text-xxs text-gray-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity">
            <strong>Last Modified: </strong>
            {formatLastModified(record.LastModifiedDate)}
          </span>
        ) : (
          <span />
        )}
        <div className="flex items-center space-x-2">
          {!isThisRecordBeingEdited ? (
            <PencilSquareIcon
              onClick={handleEditClick}
              className="h-5 w-5 md:h-6 md:w-6 lg:h-6 lg:w-6 text-indigo-600 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity"
            />
          ) : (
            <>
              <XCircleIcon
                onClick={handleCancelClick}
                className="h-8 w-8 md:h-9 md:w-9 lg:h-9 lg:w-9 text-red-300 cursor-pointer hover:text-red-500 transition-colors"
                title="Cancel"
              />
              <ArrowUpCircleIcon
                onClick={!hasValidationErrors && !onboarding ? handleSaveClick : undefined}
                className={`h-8 w-8 md:h-9 md:w-9 lg:h-9 lg:w-9 transition-colors ${hasValidationErrors || onboarding
                    ? 'text-gray-400 pointer-events-none'
                    : 'text-indigo-500 cursor-pointer hover:text-indigo-700'
                  }`}
                title="Send Updates"
              />
            </>
          )}
        </div>
      </div>

      {isThisRecordBeingEdited && (
        <div>
          <UpdatePreview record={record} draftRecord={draftRecord} sending={sending} messageId={messageId} />
        </div>
      )}
    </div>
  );
}