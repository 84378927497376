import React from 'react';
import FieldValue from './FieldValue.jsx';
import PicklistField from './fields/PicklistField.jsx';
import DateField from './fields/DateField.jsx';
import ReferenceField from './fields/reference-field/ReferenceField.jsx';
import NumberField from './fields/NumberField.jsx';
import PhoneField from './fields/PhoneField.jsx';
import EmailField from './fields/EmailField.jsx';
import UrlField from './fields/UrlField.jsx';
import DateTimeField from './fields/DateTimeField.jsx';

export default function FieldRenderer(props) {
  const { fieldDefinition } = props;
  const fieldType = fieldDefinition?.type;

  if (fieldType === 'picklist') {
    return <PicklistField {...props} />;
  }

  if (fieldType === 'date') {
    return <DateField {...props} />;
  }

  if (fieldType === 'datetime') {
    return <DateTimeField {...props} />;
  }

  if (fieldType === 'reference') {
    return <ReferenceField {...props} />;
  }

  if (fieldType === 'phone') {
    return <PhoneField {...props} />;
  }

  if (fieldType === 'email') {
    return <EmailField {...props} />;
  }

  if (fieldType === 'url') {
    return <UrlField {...props} />;
  }

  if (
    fieldType === 'autonumber' ||
    fieldType === 'double' ||
    fieldType === 'int' ||
    fieldType === 'percent' ||
    fieldType === 'currency'
  ) {
    return <NumberField {...props} />;
  }

  return <FieldValue {...props} />;
}