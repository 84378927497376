
import React from 'react';
import { useSelector } from 'react-redux';
import { mirage } from 'ldrs';

mirage.register();

const Generating = () => {

  return (
    <div className="relative transition-all duration-200 ease-in-out mb-2">
      <div className="relative transition-all duration-200 ease-in-out h-8">
        <div className="absolute left-0 top-1 flex items-center">
          <img
            src="assets/tarn_Icon_Purple.png"
            alt="Generating Icon"
            className="h-6 w-6 ml-4"
          />
        </div>

        <div className="absolute left-1/2 transform -translate-x-1/2 pt-2">
          <l-mirage size="80" speed="2.5" color="#818CF8" />
        </div>

      </div>
    </div>
  );
};

export default Generating;