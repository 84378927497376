import React from 'react';
import BackgroundGradient from '../ui/BackgroundGradient.jsx'; // Import the BackgroundGradient component
import ChatHero from './ChatHero.jsx'; // Import the ProductHero component

export default function ChatProduct() {
  return (
    <div className="relative isolate">
      <BackgroundGradient />
      <ChatHero />
    </div>
  );
}