import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  validationsByCompositeKey: {}
};

const validationSlice = createSlice({
  name: 'validation',
  initialState,
  reducers: {
    setValidationError: (state, action) => {
      const { recordId, messageId, fieldName, errorType } = action.payload;
      const key = `${messageId}_${recordId}`;
      // Create an object for this composite key if it doesn't exist
      if (!state.validationsByCompositeKey[key]) {
        state.validationsByCompositeKey[key] = { issues: {} };
      }
      // Set the error for this field
      state.validationsByCompositeKey[key].issues[fieldName] = errorType;
    },

    clearValidationError: (state, action) => {
      const { recordId, messageId, fieldName } = action.payload;
      const key = `${messageId}_${recordId}`;
      if (state.validationsByCompositeKey[key]?.issues[fieldName]) {
        delete state.validationsByCompositeKey[key].issues[fieldName];
      }
      if (
        state.validationsByCompositeKey[key] &&
        Object.keys(state.validationsByCompositeKey[key].issues).length === 0
      ) {
        delete state.validationsByCompositeKey[key];
      }
    },

    clearAllValidationErrors: (state) => {
      state.validationsByCompositeKey = {};
    }
  }
});

export const {
  setValidationError,
  clearValidationError,
  clearAllValidationErrors
} = validationSlice.actions;

export default validationSlice.reducer;